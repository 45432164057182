<template>
    <v-text-field
        outlined
        dense
        :disabled="disabled"
        :label="labelRequired"
        :value="value"
        :rules="rules"
        :type="type"
        :hide-details="hideDetails"
        @input="update" />
</template>

<script>
    export default {
        name: 'i-text-field',
        props: {
            /**
             * Binding for v-model
             * @model
             */
            value: {
                type: undefined,
            },
            label: {
                type: String,
            },
            required: {
                type: Boolean,
                default: false,
            },
            email: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            password: {
                type: Boolean,
                default: false,
            },
            rules: {
                type: Array,
                default: () => [],
            },
            hideDetails: {
                type: [Boolean, String],
                default: 'auto',
            },
        },

        computed: {
            validationRules() {
                const rules = {
                    email: this.email,
                    required: this.required,
                };

                return rules;
            },
            labelRequired() {
                if (this.required === true) {
                    return `${this.label}*`;
                }
                return this.label;
            },
            type() {
                if (this.password === true) {
                    return 'password';
                }
                return 'text';
            },
        },

        methods: {
            update(value) {
                this.$emit('input', value);
            },
        },

    };
</script>

<style>
    .v-input__control .v-input__slot {
        height: 10px !important;
        background-color: white !important;
    }
</style>
