<template>
    <div v-if="visual">
        <label v-if="label">{{ label }}</label>
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" />
    </div>
    <v-textarea outlined v-else :label="label" v-model="editorData" :row-height="rowHeight" :rows="rows" />
</template>

<script>
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

    export default {
        name: 'ITextarea',
        props: {
            value: {
                type: String,
                default: '',
            },
            label: {
                type: String,
                default: '',
            },
            visual: {
                type: Boolean,
                default: false,
            },
            rowHeight: {
                type: Number,
                default: 5,
            },
            rows: {
                type: Number,
                default: 4,
            },
        },
        data: () => ({
            editor: ClassicEditor,
            editorData: '',
            editorConfig: {},
        }),
        watch: {
            value: {
                immediate: true,
                handler(newValue) {
                    this.editorData = newValue;
                },
            },
            editorData(newValue) {
                this.$emit('input', newValue);
            },
        },
    };
</script>
