<template>
    <v-form @submit.prevent="addComment">
        <v-textarea outlined label="comment" v-model="content" />
        <v-btn type="submit" color="secondary">Add</v-btn>
    </v-form>
</template>

<script>
    import ReportAPI from './webapi';

    export default {
        name: 'ReportCommentAdd',

        props: {
            reportId: {
                type: String,
                required: true,
            },
            pageId: {
                type: String,
                required: true,
            },
        },

        data: () => ({
            content: undefined,
        }),

        methods: {
            addComment() {
                ReportAPI.commentsAdd(this.reportId, this.pageId, this.content).then(() => {
                    this.$helpers.notifySuccess('Comment added.');
                    this.content = undefined;
                    this.$emit('on-success', {});
                }).catch((err) => {
                    this.$helpers.notifyError(`Error when adding comment: ${err}.`);
                });
            },
        },
    };
</script>
