<template>
    <v-app id="app">
        <keep-alive>
            <router-view />
        </keep-alive>
        <notifications />
        <keep-alive>
            <facebook-messenger />
        </keep-alive>
    </v-app>
</template>

<script>
    import MainAPI from '@/apps/main/webapi';
    import bus from '@/ebus';
    import { Notifications } from '@/components/Notifications';
    import FacebookMessenger from '@/components/FacebookMessenger.vue';

    export default {
        name: 'App',

        components: {
            Notifications,
            FacebookMessenger,
        },

        data: () => ({
            metadataRequest: () => MainAPI.getMetadata(),
        }),

        created() {
            bus.$on('forbidden', () => {
                this.$router.push('/auth/logout', () => {});
                console.log(this);
                this.$helpers.notifyInfo('Your session expired. Please log in.');
            });
        },
    };
</script>

<style>
    #app {
        background: #f2f5f8;
    }
</style>
