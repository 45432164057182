<template>
    <v-row align="center" align-content="center" justify="center">
        <v-col cols=12 sm=9 md=6 lg=5 xl=3 align-self="center">
            <v-card id="login-card">
                <v-card-title color="white" class="primary white--text">
                    <v-img
                        class="mx-2"
                        src="/img/logo-white.png"
                        max-width="350"
                        contain
                    />
                </v-card-title>
                <v-card-title class="primary white--text pa-6">
                    Create Account
                </v-card-title>
                <v-card-text>
                    <ValidationObserver v-slot="{ invalid }">
                        <v-col>
                            <i-text-field v-model="email" label="Email" required email />
                        </v-col>
                        <v-col>
                            <i-text-field v-model="password1" label="Password" required password />
                        </v-col>
                        <v-col>
                            <i-text-field v-model="password2" label="Password Confirm" required password />
                        </v-col>
                        <v-col>
                            <v-btn
                                @click="login(email, password1, password2)"
                                :disabled="invalid" color="primary">
                                Login
                            </v-btn>
                        </v-col>
                    </ValidationObserver>
                    <v-alert
                        type="error"
                        v-if="error"
                    >
                        {{ error }}
                    </v-alert>
                    <v-col>
                        <router-link to="/auth/login">Already have an account? Log in.</router-link>
                    </v-col>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';

    export default {
        name: 'AuthLogin',
        components: {
            ValidationObserver,
        },
        data: () => ({
            email: undefined,
            password1: undefined,
            password2: undefined,
            error: undefined,
        }),
        methods: {
            login(email, password1, password2) {
                this.$store.dispatch('auth/createAccount', {
                    email, password1, password2,
                })
                    .then(() => this.$router.push({ name: 'auth-register-confirm' }))
                    .catch((err) => { this.error = err.message; });
            },
        },
    };
</script>
