<template>
    <v-row align="center" align-content="center" justify="center">
        <v-col cols=12 sm=9 md=6 lg=5 xl=3 align-self="center">
            <v-card>
                <v-card-title color="white">
                    Goodish Data Platform
                </v-card-title>
                <v-card-subtitle>
                    Confirm email
                </v-card-subtitle>
                <v-card-text>
                    <br>
                    Please check your email with confirmation steps.
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'AuthRegisterConfirm',
    };
</script>

<style scoped>
    .v-card__title, .v-card__subtitle {
        background: #24a575;
        color: #fff !important;
    }
</style>
