<template>
    <request :request="request" @on-success="onRequestSuccess">
        <v-row>
            <v-col cols=12>
                <comments-list :comments="comments" />
            </v-col>
            <v-col cols=12>
                <comments-add
                    :report-id="reportId"
                    :page-id="pageId"
                    @on-success="onCommentAddSuccess"
                />
            </v-col>
        </v-row>
    </request>
</template>

<script>
    import CommentsAdd from './CommentsAdd.vue';
    import CommentsList from './CommentsList.vue';
    import ReportAPI from './webapi';

    export default {
        name: 'ReportComments',

        components: {
            CommentsAdd,
            CommentsList,
        },

        props: {
            reportId: {
                type: String,
                required: true,
            },
            pageId: {
                type: String,
                required: true,
            },
        },

        data: () => ({
            request: undefined,
            comments: [],
        }),

        watch: {
            pageId: {
                immediate: true,
                handler(newValue) {
                    if (newValue !== undefined) {
                        this.request = () => ReportAPI.commentsList(this.reportId, newValue);
                    }
                },
            },
        },

        methods: {
            onRequestSuccess(data) {
                this.comments = data.comments;
            },
            onCommentAddSuccess() {
                // Refresh the list of comments.
                if (this.pageId !== undefined) {
                    this.request = () => ReportAPI.commentsList(this.reportId, this.pageId);
                }
            },
        },
    };
</script>
