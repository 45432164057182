import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import authStore from '@/apps/auth/store';
import { notificationStore } from '@/components/Notifications';
import coreStore from '@/core/store';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        auth: authStore,
        notifications: notificationStore,
        core: coreStore,
    },
    strict: debug,
    plugins: debug ? [createLogger()] : [],
});
