import VueRouter, { Route, RouteConfig } from 'vue-router';
import state from '@/store';
import { requireAuthenticated, requireStaff, multiguard } from '@/utils';
// import Layout from './Layout.vue';
// import Layout from '@/Layout.vue';
import ReportHomePage from './pages/ReportHomePage.vue';
import ReportBuilderPage from './pages/ReportBuilder.vue';
import ReportViewPage from './pages/ReportView.vue';

const APP_ICON = 'fa-chart-line';
const APP_NAME = 'Report';

const app = { name: APP_NAME, icon: APP_ICON };

const createBreadcrumbs = (breadcrumbs: Array<Record<string, any>>) => () => breadcrumbs;

const routes: Array<RouteConfig> = [
    {
        path: '/report',
        name: 'report-home',
        component: ReportHomePage,
        meta: {
            app,
            breadcrumbs: createBreadcrumbs(
                [
                    {
                        disabled: true, exact: true, link: true, text: 'Reports', to: { name: 'report-home' },
                    },
                ],
            ),
        },
        beforeEnter: requireAuthenticated,
    },
    {
        path: 'report/view/:reportId',
        props: true,
        name: 'report-view',
        component: ReportViewPage,
        meta: {
            app,
            breadcrumbs: createBreadcrumbs(
                [
                    {
                        disabled: false, exact: true, link: true, text: 'Reports', to: { name: 'report-home' },
                    },
                    {
                        disabled: true, exact: true, link: true, text: 'View', to: { name: 'report-view' },
                    },
                ],
            ),
        },
        beforeEnter: requireAuthenticated,
    },
];

// EXPORT
export default routes;
