<template>
    <v-row>
        <v-col class="text-left">
            <v-card class="pa-20" outlined>
                <v-card-title>{{ page.title }}</v-card-title>

                <v-card-text>
                    <div v-for="com in page.components" :key="com.cid">
                        <component v-bind:is="getComponent(com.key)" :spec="com.data" />
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

</template>

<script>
    import RIframe from './Iframe.vue';
    import RText from './Text.vue';

    const components = {
        iframe: 'RIframe',
        text: 'RText',
    };

    export default {
        name: 'ReportPageView',

        components: {
            RIframe,
            RText,
        },

        props: {
            page: {
                type: Object,
                required: true,
            },
        },

        methods: {
            getComponent(componentKey) {
                const com = components[componentKey];
                return com;
            },
        },

    };
</script>
