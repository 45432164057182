<template>
    <div>
        <snackbars :objects="notifications" @delete-notification="deleteNotify"/>
        <div v-if="debug">
            <i-text-field v-model="notification" />
            <v-btn @click="notify">Notify</v-btn>
        </div>
    </div>
</template>

<script>
    // import NotificationSnackbar from './NotificationSnackbar.vue';
    import Snackbars from './Snackbars.vue';

    export default {
        name: 'Notifications',
        components: {
            // NotificationSnackbar,
            Snackbars,
        },
        data: () => ({
            debug: false,
            seen: true,
            notification: undefined,
        }),
        computed: {
            notifications() {
                return this.$store.state.notifications.notifications;
            },
        },
        methods: {
            notify() {
                this.$helpers.notify(this.notification, {});
            },
            deleteNotify(key) {
                this.$helpers.notifyDelete(key);
            },
        },
    };
</script>
