<template functional>
    <component
        class="i-breadcrumbs"
        :is="$options.VBreadcrumbs"
        :key="data.key"
        :items="props.items"
    >
        <slot></slot>
    </component>
</template>

<script>
    import { VBreadcrumbs } from 'vuetify/lib';
    import { getAttr } from './utils';

    export default {
        name: 's-breadcrumbs',
        VBreadcrumbs,
        props: {
            items: {
                type: Array,
                required: true,
            },
        },
        getAttr,
    };
</script>

<style scoped>
    ul.i-breadcrumbs {
        padding-left: 12px !important;
    }
</style>
