<template>
    <v-main class="fill-height">
        <v-container
            fluid class="fill-height"
        >
            <router-view :key="$route.path" />
        </v-container>
    </v-main>
</template>

<script>
    export default {
        name: 'LogoutLayout',
    };
</script>

<style scoped>
    .v-main {
        background-color: #dedede;
    }
</style>
