<template>
    <v-app-bar
        app
        elevation=0
        clipped-right
        clipped-left
        color="primary"
    >
        <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.mobile"
            color="white"
            @click="$emit('drawer-click')">
            <i-icon icon="$drawer" />
        </v-app-bar-nav-icon>
        <v-img
            class="mx-2"
            src="/img/logo-white.png"
            max-width="250"
            contain
        />

        <v-spacer />
        <user-menu :username="user.email" :account-type="user_role_name" />
    </v-app-bar>
</template>

<script>
    import UserMenu from './UserMenu.vue';

    export default {
        name: 'platform-bar',
        components: {
            UserMenu,
        },
        props: {
            value: {
                type: Boolean,
                default: false,
            },
            applications: {
                type: Array,
                required: true,
            },
        },
        data: () => ({
            drawer: false,
            drawerRight: false,
        }),

        methods: {
            logout() {
                this.$store.dispatch('auth/logout');
            },
            metadata() {
                this.$store.dispatch('auth/metadata');
            },
        },
        computed: {
            user() {
                const user = this.$store.getters['auth/user'] || {};
                return user;
            },
            user_role_name() {
                if (this.user.role) {
                    return this.user.role.name;
                }
                return '';
            },
        },
    };
</script>
