<template>
    <request :request="request" @on-success="onRequestSuccess">
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>Reports</v-card-title>
                    <v-simple-table class="text-left">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th/>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="report in reports"
                                    :key="report.key"
                                    @click="showReport(report.key)"
                                >
                                    <td>
                                        <b>{{ report.name }}</b>
                                        <br>
                                        <small>{{ report.desc  }}</small>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>
        <custom-report-dialog-viewer :report-id="selectedReportId" v-model="modal" />
    </request>
</template>

<script>
    import CustomReportAPI from '@/components/CustomReport/webapi';
    import CustomReportDialogViewer from './ReportDialogViewer.vue';

    export default {
        name: 'CustomReportList',

        components: {
            CustomReportDialogViewer,
        },

        data: () => ({
            request: undefined,
            reports: undefined,
            selectedReportId: undefined,
            modal: false,
        }),

        created() {
            this.request = () => CustomReportAPI.listReports();
        },

        methods: {
            onRequestSuccess(data) {
                this.reports = data.reports;
            },
            navigateToReport(reportId) {
                this.$router.push({ name: 'customreport-view', params: { reportId } });
            },
            showReport(reportId) {
                this.selectedReportId = reportId;
                this.modal = true;
            },
        },
    };
</script>

<style>
    tr {
        cursor: pointer;
    }
</style>
