<template>
    <div style="max-width: 2800px">
        <request :request="filesRequest" @on-success="onFilesSuccess">
            <v-card>
                <v-card-title>Chats</v-card-title>
                <v-card-text>
                    <v-simple-table >
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Uploaded at
                                    </th>
                                    <th class="text-left">
                                        Filename
                                    </th>
                                    <th class="text-left">
                                        Options
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="item in files"
                                    :key="item.uid"
                                >
                                    <td>{{ item.created_at | formatDatetime }}</td>
                                    <td>{{ item.src_filename }}</td>
                                    <td>
                                        <v-btn
                                            icon
                                            color=""
                                            :to="{name: 'chat-analysis', params: {fileId: item.uid}}"
                                        >
                                            <v-icon small>{{ '$view' }}</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <div v-if="!hasFiles">
                        <v-btn color="secondary" small :to="{name: 'chat-new'}"><i-icon icon="fa-plus" /> New analaysis</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </request>
    </div>
</template>

<script>
    import du from '@/utils/dateutils';
    import ChatAnalysisApi from '../webapi';

    export default {
        name: 'chat-analysis-pages-home',
        data: () => ({
            filesRequest: () => ChatAnalysisApi.getFiles(),
            files: [],
        }),
        computed: {
            hasFiles() {
                return this.files.length > 0;
            },
        },
        methods: {
            onFilesSuccess(data) {
                this.files = data;
            },
        },
        filters: {
            formatDatetime(val) {
                return du.datetime(val);
            },
        },
    };
</script>
