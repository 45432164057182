<template>
    <v-snackbar
        v-model="snackbar"
        :color="color"
    >
        <div>
            <h3 v-if="title">{{ title }}</h3>
        </div>
        <div>
            {{ text }}
        </div>
        <v-btn
            text
            @click="closeSnackbar"
        >
            Close
        </v-btn>
    </v-snackbar>
</template>

<script>
    export default {
        name: 'ISnackbar',
        props: {
            value: {
                type: Boolean,

            },
            text: {
                type: String,
                required: true,
            },
            title: {
                type: String,
                default: undefined,
            },
            color: {
                type: String,
                default: 'info',
            },
        },
        data() {
            return {
                snackbar: false,
            };
        },
        watch: {
            value: {
                immediate: true,
                handler(newValue) {
                    this.snackbar = newValue;
                },
            },
            snackbar(newValue) {
                this.$emit('input', newValue);
            },
        },
        methods: {
            closeSnackbar() {
                this.$emit('input', false);
            },
        },
    };
</script>
