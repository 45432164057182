<template>
    <request :request="request" @on-success="onRequestSuccess">
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card color="#fafafa" v-if="report">
                <v-toolbar
                    dense
                    color="secondary"
                    dark
                >
                    <v-btn
                        icon
                        @click="dialog = false"
                    >
                        <v-icon>fa-times</v-icon>
                    </v-btn>
                    <v-toolbar-title><b>Report:</b> {{ report.name }} </v-toolbar-title>
                </v-toolbar>
                <br>
                <v-card-text>
                    <v-row>
                        <v-col cols=3>
                            <report-page-list :pages="report.pages" v-if="report"  @on-page-change="changePage"/>
                        </v-col>
                        <v-col cols=9>
                            <report-page-viewer :report-id="reportId" :page-id="selectedPageId" v-if="selectedPageId" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </request>
</template>

<script>
    import CustomReportAPI from '@/components/CustomReport/webapi';
    import ReportPageList from './ReportPageList.vue';
    import ReportPageViewer from './ReportPageViewer.vue';

    export default {
        name: 'CustomReportDialogViewr',

        components: {
            ReportPageViewer,
            ReportPageList,
        },

        props: {
            reportId: {
                type: String,
                default: undefined,
            },
            value: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            selectedPageId: undefined,
            dialog: false,
            request: undefined,
            report: undefined,
        }),

        watch: {
            value: {
                immediate: true,
                handler(newValue) {
                    this.dialog = newValue;
                },
            },
            reportId: {
                immediate: true,
                handler(newValue) {
                    if (newValue !== undefined) {
                        this.selectedPageId = undefined;
                        this.report = undefined;
                        this.request = () => CustomReportAPI.getReport(newValue);
                    }
                },
            },
            dialog(newValue) {
                this.$emit('input', newValue);
            },
        },

        methods: {
            onRequestSuccess(data) {
                this.report = data;
            },
            changePage(pageId) {
                this.selectedPageId = pageId;
            },
        },
    };
</script>
