<template>
    <v-row justify="center">
        <v-form ref="profileForm" @submit.prevent="saveUserProfile" v-model="profileForm">
            <v-card>
                <v-card-title class="">
                    <span class="headline">User Profile</span>
                </v-card-title>
                <v-card-text>
                    <p>Pleass add additional information.</p>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="6"
                            md="6"
                        >
                            <i-text-field v-model="firstName" label="First Name" :rules="requiredRules" />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="6"
                        >
                            <i-text-field v-model="lastName" label="Last Name" :rules="requiredRules" />
                        </v-col>
                        <v-col cols="12">
                            <i-text-field v-model="jobTitle" label="Job Title"  :rules="requiredRules" />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                        >
                            <v-autocomplete
                                v-model="industry"
                                outlined
                                dense
                                :items="industryItems"
                                item-text="name"
                                item-value="key"
                                label="Industry"
                                :rules="requiredRules"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            v-show="otherEnabled"
                        >
                            <i-text-field label="Please, provide the industry" v-model="industryOther" />
                        </v-col>
                        <v-col cols=12>
                            <small>*indicates required field</small>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        type="submit"
                        color="secondary"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-row>
</template>

<script>
    import { rules } from '@/utils';
    import MainAPI from '../webapi';

    export default {
        name: 'OnboardingComponent',
        props: {
            show: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            profileForm: undefined,
            requiredRules: [
                rules.required(),
            ],
            industryItems: [
                { name: 'E-commerce', key: 'ecommerce' },
                { name: 'SaaS', key: 'saas' },
                { name: 'Other', key: 'other' },
            ],
            industry: undefined,
            industryOther: undefined,
            firstName: undefined,
            lastName: undefined,
            jobTitle: undefined,
        }),
        computed: {
            otherEnabled() {
                return this.industry === 'other';
            },
        },
        methods: {
            saveUserProfile() {
                if (this.$refs.profileForm.validate() === true) {
                    const profile = {
                        firstName: this.firstName,
                        lastName: this.lastName,
                        properties: {
                            industry: this.industry === 'other' ? this.industryOther : this.industry,
                            jobTitle: this.jobTitle,
                        },
                    };

                    MainAPI.saveUserProfile(profile).then(() => {
                        this.$store.dispatch('refreshMetadata');
                        this.$router.push({ name: 'main-home' });
                    });
                }
            },
        },
    };
</script>
