import webapi from '@/core/webapi';
import CONSTANTS from '@/constants';

const BASE_URI = `${CONSTANTS.API_BASE}/chat-analysis`;

export default class ChatAnalaysisApi {
    static async getFiles() : Promise<any> {
        const uri = `${BASE_URI}`;
        return webapi.get({ uri });
    }

    static async setColumnMap(chatUid: string, columnMap: Record<string, string>) : Promise<any> {
        const uri = `${BASE_URI}/${chatUid}/columns-map`;
        const body = {
            columns_map: columnMap,
        };
        return webapi.post({ uri, body });
    }

    static async uploadFile(file: Blob) : Promise<any> {
        const uri = `${BASE_URI}/upload`;

        const body = new FormData();
        body.append('file', file);

        return webapi.upload({ uri, body });
    }

    static async getAnalysis(chatUid: string) : Promise<any> {
        const uri = `${BASE_URI}/${chatUid}/analysis`;
        return webapi.get({ uri });
    }
}
