<template>
    <v-row>
        <v-col>
            <report-view :report-id="reportId" />
        </v-col>
    </v-row>
</template>

<script>
    import ReportView from '@/components/CustomReport/ReportView.vue';

    export default {
        name: 'CustomReportViewPage',

        components: {
            ReportView,
        },

        props: {
            reportId: {
                type: String,
                required: true,
            },
        },
    };
</script>
