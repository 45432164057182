<template>
    <v-list class="text-left" color="transparent" dense>
        <v-list-item>
            <b>COMMENTS</b>
        </v-list-item>
        <template v-for="item in comments">

            <v-list-item
                :key="item.title"
            >
                <v-list-item-content>
                    <comment-display
                        :comment-id="item.id"
                        :user="item.author.first_name"
                        :content="item.content" />
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
    import CommentDisplay from './CommentDisplay.vue';

    export default {
        name: 'ReportCommentList',

        components: {
            CommentDisplay,
        },

        props: {
            comments: {
                type: Array,
                default: () => [],
            },
        },

        data: () => ({
        }),
    };
</script>
