import { Route } from 'vue-router';

function evaluateGuards(guards: Array<Function>, to: Route, from: Route, next: Function) {
    const guardsLeft = guards.slice(0); // clone the array so we do not accidentally modify it
    const nextGuard = guardsLeft.shift();

    if (nextGuard === undefined) {
        next();
        return;
    }

    nextGuard(to, from, (nextArg: any) => {
        if (nextArg === undefined) {
            evaluateGuards(guardsLeft, to, from, next);
            return;
        }

        next(nextArg);
    });
}

export default function multiguard(guards: Array<Function>) {
    if (!Array.isArray(guards)) {
        throw new Error('You must specify an array of guards');
    }

    return (to: Route, from: Route, next: Function) => evaluateGuards(guards, to, from, next);
}
