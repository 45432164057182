<template functional>
    <component
        :is="$options.ValidationProvider"
        :key="data.key"
        :rules="$options.getValidationRules(props)"
        name="TextFieldValidationProvider"
        v-slot="{ errors }" >
        <component
            :is="$options.VTextField"
            :key="data.key"
            :value="props.value"
            :label="props.label"
            v-on="{ input: listeners.input || function() {}, }"
            outlined
            :disabled="$options.getAttr(data.attrs, 'disabled')"
            :clearable="$options.getAttr(data.attrs, 'clearable')"
            :mask="$options.getAttr(data.attrs, 'mask') || undefined"
            :counter="$options.getAttr(data.attrs, 'counter') || undefined"
            :hint="$options.getAttr(data.attrs, 'hint') || undefined"
            :hide-details="!$options.getAttr(data.attrs, 'hint') && !$options.getAttr(data.attrs, 'name')"
            persistent-hint
            :prefix="$options.getAttr(data.attrs, 'prefix') || undefined"
            :suffix="$options.getAttr(data.attrs, 'suffix') || undefined"
            :appendIcon="$options.getAttr(data.attrs, 'append-icon') || undefined"
            :type="$options.getAttr(data.attrs, 'type') || undefined"
            :errorMessages="errors"
            :name="$options.getAttr(data.attrs, 'name') || $options.NO_NAME_ASSIGNED"
            :step="$options.getAttr(data.attrs, 'step') || undefined"
            dense
            background-color="white"
            :required="props.required"
        />
    </component>
</template>

<script>
    import { ValidationProvider, extend } from 'vee-validate';
    import { required, email } from 'vee-validate/dist/rules';
    import { VTextField } from 'vuetify/lib';
    import { getAttr, getValidationRules, NO_NAME_ASSIGNED } from './utils';

    extend('email', email);
    extend('required', {
        ...required,
        message: 'This field is required',
    });

    export default {
        name: 'i-text-field',
        ValidationProvider,
        VTextField,
        props: {
            /**
             * Binding for v-model
             * @model
             */
            value: {
                type: undefined,
            },
            label: {
                type: String,
            },
            required: {
                type: Boolean,
                default: false,
            },
            email: {
                type: Boolean,
                default: false,
            },
        },
        getValidationRules,
        getAttr,
        NO_NAME_ASSIGNED,
    };
</script>

<style>
    .v-input__control .v-input__slot {
        height: 10px !important;
    }
</style>
