<template>
    <v-row>
        <v-col>
            <report-table @click-report="onReportClick" />

        </v-col>
        <view-report v-model="showReport" :reportId="selectedReportId" />
    </v-row>
</template>

<script>
    import ReportTable from '@/components/Report/Viewer/TableAlt.vue';
    import ViewReport from '../components/ViewReport.vue';

    export default {
        name: 'ReportHomePage',

        components: {
            ReportTable,
            ViewReport,
        },

        data: () => ({
            selectedReportId: undefined,
            showReport: false,
        }),

        methods: {
            onReportClick(reportId) {
                this.selectedReportId = reportId;
                this.showReport = true;
            },
        },
    };
</script>
