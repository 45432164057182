<template>
    <v-navigation-drawer
        v-model="drawer"
        app
        color="#f2f5f8"
        left
        clipped
    >
        <v-list dense>
            <slot name="app-left-drawer">
                <div v-for="(item, idx) in items" :key="idx">
                    <v-subheader v-if="item.kind === 'header'" class="mt-2">{{ item.name  }}</v-subheader>
                    <v-list-group
                        v-else-if="item.kind === 'group'"

                    >
                        <template v-slot:activator>
                            <v-list-item-title>{{ item.name  }}</v-list-item-title>
                        </template>

                        <v-list-item v-for="child in item.children" :to="{name: child.to}" exact class="text-left" :key="child.name">
                            <v-list-item-icon v-if="child.icon" class="mr-10">
                                <v-icon size="small" dense>{{ child.icon }} </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ child.name }} </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item v-else-if="item.href" :href="item.href" class="text-left">
                        <v-list-item-icon v-if="item.icon" class="mr-10">
                            <v-icon size="small" dense>{{ item.icon }} </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.name }} </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{name: item.to}" exact v-else class="text-left">
                        <v-list-item-icon v-if="item.icon" class="mr-10">
                            <v-icon size="small" dense>{{ item.icon }} </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.name }} </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </slot>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import constants from '@/constants';

    export default {
        props: {
            app: {
                type: Boolean,
                default: false,
            },
            clipped: {
                type: Boolean,
                default: false,
            },
            right: {
                type: Boolean,
                default: false,
            },
            value: {
                type: Boolean,
                default: false,
            },
            enabledApplications: {
                type: Array,
                default: () => [],
            },
        },
        data: () => ({
            drawer: true,
        }),

        computed: {
            items() {
                return constants.APPLICATION_DATA.filter((item) => this.enabledApplications.includes(item.key));
            },
        },

        watch: {
            value: {
                immediate: true,
                handler(newValue) {
                    this.drawer = newValue;
                },
            },
            drawer(newValue) {
                this.$emit('input', newValue);
            },

        },
    };
</script>

<style>
    .v-navigation-drawer__border {
        background-color: transparent !important;
        border: none !important;
    }
</style>
