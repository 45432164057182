import webapi from '@/core/webapi';
import CONSTANTS from '@/constants';

const BASE_URI = `${CONSTANTS.API_BASE}/rest-auth`;
const HOME_URI = `${CONSTANTS.API_BASE}/home`;

export default class WebApi {
    static webapi = webapi;

    static async login(email: string, password: string) : Promise<any> {
        const uri = `${BASE_URI}/login/`;
        const body = {
            email,
            password,
        };
        return webapi.post({ uri, body });
    }

    static async logout() : Promise<any> {
        // Implements logout of the user.
        const uri = `${BASE_URI}/logout/`;
        return webapi.post({ uri });
    }

    static async confirmEmail(confirmKey: string) : Promise<any> {
        const uri = `${BASE_URI}/registration/verify-email/`;
        const body = { key: confirmKey };
        return webapi.post({ uri, body });
    }

    static async register(
        email: string, password1: string, password2: string,
    ) : Promise<any> {
        const uri = `${BASE_URI}/registration/`;
        const body = {
            email,
            password1,
            password2,
        };
        return webapi.post({ uri, body });
    }

    static async getMetadata() : Promise<any> {
        const uri = `${HOME_URI}/metadata`;
        return webapi.get({ uri });
    }
}
