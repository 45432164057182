import VueRouter, { RouteConfig } from 'vue-router';
import { requireAuthenticated } from '@/utils';
import Layout from './Layout.vue';
import Home from './pages/Home.vue';

const APP_ICON = '$chat';
const APP_NAME = 'Chat analysis';

const app = { name: APP_NAME, icon: APP_ICON };

const loadPage = (page: string) => () => import(`./pages/${page}.vue`);

const routes: Array<RouteConfig> = [
    {
        path: '/chat',
        name: 'chat-home',
        component: Home,
        meta: { app },
        beforeEnter: requireAuthenticated,
    },
    {
        path: '/chat/new',
        name: 'chat-new',
        component: loadPage('Add'),
        meta: { app },
        beforeEnter: requireAuthenticated,
    },
    {
        path: '/chat/analysis/:fileId',
        name: 'chat-analysis',
        meta: { app },
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "chat-analysis-page-analysis" */ './pages/Analysis.vue'),
        beforeEnter: requireAuthenticated,
    },
];

// EXPORT
export default routes;
