<template functional>
    <component
        :is="$options.VSelect"
        :key="data.key"
        :value="props.value"
        :items="props.items"
        itemValue="key"
        v-on="{
            change: listeners.input || function() {},
        }"
        outlined
        :disabled="$options.getAttr(data.attrs, 'disabled')"
        :hint="$options.getAttr(data.attrs, 'hint') || undefined"
        :hide-details="!$options.getAttr(data.attrs, 'hint') && !$options.getAttr(data.attrs, 'name')"
        persistent-hint
        :clearable="$options.getAttr(data.attrs, 'clearable')"
        :errorMessages="$options.getAttr(data.attrs, 'error-messages') || undefined"
        v-validate="$options.getAttr(data.attrs, 'v-validate') || undefined"
        :name="$options.getAttr(data.attrs, 'name') || $options.NO_NAME_ASSIGNED"
        dense
        menu-props="offsetY"
    />
</template>

<script>
    import { VSelect } from 'vuetify/lib';
    import { getAttr, NO_NAME_ASSIGNED } from './utils';

    export default {
        name: 's-select',
        VSelect,
        props: {
        },
        getAttr,
        NO_NAME_ASSIGNED,
    };
</script>
