import '@fortawesome/fontawesome-free/css/all.css';
import 'vuetify/dist/vuetify.min.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
// ECHARTS  6.0.x
// import { use } from 'echarts/core';
// import * as echarts from 'echarts';
// import 'echarts/lib/component/grid';
// import ECharts from 'vue-echarts';
// import ECharts modules manually to reduce bundle size
// import { CanvasRenderer } from 'echarts/renderers';
// import { BarChart } from 'echarts/charts';
// import { GridComponent, TooltipComponent } from 'echarts/components';

// import ECharts from 'vue-echarts';
// import 'echarts/lib/chart/bar';
// import 'echarts/lib/chart/line';
// import 'echarts/lib/component/tooltip';

// CUSTOM
import Request from '../core/components/Request.vue';
import Pii from '../core/components/Pii.vue';
import ds from '../core/ds';

// Vue.component('v-chart', ECharts);
Vue.component('request', Request);
Vue.component('pii', Pii);
Vue.component('i-card', ds.ICard);
Vue.component('i-delete-btn', ds.IDeleteBtn);
Vue.component('i-icon', ds.IIcon);
Vue.component('i-snackbar', ds.ISnackbar);
Vue.component('i-text-field', ds.ITextField);
Vue.component('i-textarea', ds.ITextarea);

const ICONS = {
    add: 'fa-plus',
    agency: 'fa-briefcase',
    customer: 'fa-user',
    delete: 'fa-trash',
    download: 'fa-download',
    drawer: 'fa-bars',
    home: 'fa-home',
    host: 'fa-mug-hot',
    product: 'fa-map-marker',
    order: 'fas fa-shopping-cart',
    user: 'fa-user',
    edit: 'fa-pen',
    view: 'fa-eye',
    save: 'fa-save',
    help: 'fa-question-circle',
    notifications: 'fa-bell',
    dropdown: 'fa-angle-down',
    chat: 'fa-comments',
    close: 'fa-times-circle',
    data: 'fa-database',
    model: 'fas fa-microchip',
    filter: 'fa-filter',
};

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#14583E',
                lightGrey: colors.grey.base,
            },
        },
    },
    icons: {
        values: ICONS,
    },
});
