import VueRouter, { Route, RouteConfig } from 'vue-router';
import state from '@/store';
import { requireAuthenticated, multiguard } from '@/utils';
// import Layout from './Layout.vue';
import Layout from '@/Layout.vue';
import Home from './pages/Home.vue';
import Onboarding from './pages/Onboarding.vue';

const APP_ICON = '$home';
const APP_NAME = 'Home';

const app = { name: APP_NAME, icon: APP_ICON };

const profileGuard = (to: Route, from: Route, next: Function) => {
    if (state.getters['auth/hasUserProfile'] === true) {
        next({ name: 'main-home' });
    } else {
        next();
    }
};

const noBreadcrumbs = () => [];

const routes: Array<RouteConfig> = [
    {
        path: '',
        name: 'main-home',
        component: Home,
        meta: { app, breadcrumbs: noBreadcrumbs },
        beforeEnter: requireAuthenticated,
    },
];

// EXPORT
export default routes;
