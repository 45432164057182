import constants from '@/constants';
import webapi from '@/core/webapi';

const BASE_URI = `${constants.API_BASE}/report/client`;

export default class ReportAPI {
    static async listReports() : Promise<any> {
        const uri = `${BASE_URI}`;
        return webapi.get({ uri });
    }

    static async getReport(reportId: String) : Promise<any> {
        const uri = `${BASE_URI}/${reportId}`;
        return webapi.get({ uri });
    }

    // COMMENTS
    static async commentsList(reportId: String, pageId: String) : Promise<any> {
        const uri = `${BASE_URI}/${reportId}/comments/${pageId}`;
        return webapi.get({ uri });
    }

    static async commentsAdd(reportId: String, pageId: String, content: String) : Promise<any> {
        const uri = `${BASE_URI}/${reportId}/comments/${pageId}`;
        const body = { content };
        return webapi.post({ uri, body });
    }

    static async commentsUpdate(
        reportId: String, pageId: String, commentId: String, content: String,
    ) : Promise<any> {
        const uri = `${BASE_URI}/${reportId}/comments/${pageId}/${commentId}`;
        const body = { content };
        return webapi.put({ uri, body });
    }

    static async commentsDelete(
        reportId: String, pageId: String, commentId: String,
    ) : Promise<any> {
        const uri = `${BASE_URI}/${reportId}/comments/${pageId}/${commentId}`;
        return webapi.delete({ uri });
    }
}
