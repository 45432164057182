<template>
    <div>
        <report-table @click-report="onReportClick" />
        <view-report v-model="showReport" :reportId="selectedReportId" />
    </div>
</template>

<script>
    import ReportTable from '@/components/Report/Viewer/TableAlt.vue';
    import ViewReport from '@/apps/report/components/ViewReport.vue';

    export default {
        name: 'ReportHomePage',

        components: {
            ReportTable,
            ViewReport,
        },

        data: () => ({
            selectedReportId: undefined,
            showReport: false,
        }),

        methods: {
            onReportClick(reportId) {
                this.selectedReportId = reportId;
                this.showReport = true;
            },
        },
    };
</script>
