import constants from '@/constants';
import webapi from '@/core/webapi';

const BASE_URI = `${constants.API_BASE}/gateway/reports`;

export default class CustomReportAPI {
    static async listReports() : Promise<any> {
        const uri = `${BASE_URI}/list-reports`;
        return webapi.get({ uri });
    }

    static async getReport(reportId: string) : Promise<any> {
        const uri = `${BASE_URI}/report/${reportId}`;
        return webapi.get({ uri });
    }

    static async getReportPage(reportId: string, pageId: string) : Promise<any> {
        const uri = `${BASE_URI}/report/${reportId}/page/${pageId}`;
        return webapi.get({ uri });
    }
}
