<template>
    <div>
        <!-- Load Facebook SDK for JavaScript -->
        <div id="fb-root"></div>
        <!-- Your Chat Plugin code -->
        <div class="fb-customerchat"
             attribution="setup_tool"
             page_id="108295450578219">
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    export default {
        name: 'FacebookMessenger',

        mounted() {
            window.fbAsyncInit = function() {
                FB.init({
                    xfbml            : true,
                    version          : 'v10.0'
                });
            };

            (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        },
    };
</script>
