<template>
    <div :class="textClass">{{ spec.content }}</div>
</template>

<script>
    export default {
        name: 'CustomReportComTitle',

        props: {
            com: {
                type: String,
                required: true,
            },
            spec: {
                type: Object,
                required: true,
            },
        },

        computed: {
            textClass() {
                if (this.com === 'h2') {
                    return 'text-left text-h6';
                }
                if (this.com === 'h3') {
                    return 'text-left text-subtitle-1';
                }
                return 'text-left';
            },
        },
    };
</script>
