import Cookies from 'js-cookie';
import bus from '@/ebus';
import WebError from './webError';

interface Request {
    uri: string,
    body?: FormData | object,
}

type Headers = Record<string, string>;

const SESSION_EXPIRED_TITLE = 'Session Expired';
const SESSION_EXPIRED_MESSAGE = 'Your session expired. You need to log in.';

export default class WebAPI {
    static authorizationToken: string | null = null;

    static async get(obj: Request) : Promise<any> {
        const { uri } = obj;
        const headers = {
            Authorization: `Token ${WebAPI.authorizationToken}`,
        };
        const response = await fetch(uri, { credentials: 'include' });
        if (response.status === 403) {
            bus.$emit('forbidden');
            throw new WebError(SESSION_EXPIRED_TITLE, SESSION_EXPIRED_MESSAGE);
        }
        const data = await response.json();
        return data;
    }

    static async post(obj: Request) : Promise<any> {
        const { uri } = obj;
        const { body } = obj;
        const method = 'POST';

        const headers: Headers = {};
        headers.Authorization = `Token ${WebAPI.authorizationToken}`;
        headers['Content-Type'] = 'application/json';

        const csrftoken = Cookies.get('csrftoken');
        if (csrftoken !== undefined) {
            headers['X-CSRFToken'] = csrftoken;
        } else {
            console.warn('No Csrftoken set', csrftoken, document.cookie);
        }

        const response = await fetch(uri, {
            method,
            credentials: 'include',
            body: JSON.stringify(body),
            headers,
        });

        if (response.status === 403) {
            bus.$emit('forbidden');
            throw new WebError(SESSION_EXPIRED_TITLE, SESSION_EXPIRED_MESSAGE);
        }

        if (response.ok === true) {
            const data = await response.json();
            return data;
        }

        const data = await response.json();

        if ('non_field_errors' in data) {
            const title = 'Error';
            const message = data.non_field_errors.join(' ');
            throw new WebError(title, message);
        }

        throw Error(`${response.statusText} (${response.status})`);
    }

    static async put(obj: Request) : Promise<any> {
        const { uri } = obj;
        const { body } = obj;
        const method = 'PUT';
        const csrftoken = Cookies.get('csrftoken');

        const headers: Headers = {};
        headers.Authorization = `Token ${WebAPI.authorizationToken}`;
        headers['Content-Type'] = 'application/json';
        if (csrftoken !== undefined) {
            headers['X-CSRFToken'] = csrftoken;
        } else {
            console.warn('No Csrftoken set', csrftoken, document.cookie);
        }

        const response = await fetch(uri, {
            method,
            credentials: 'include',
            body: JSON.stringify(body),
            headers,
        });

        if (response.status === 403) {
            bus.$emit('forbidden');
            throw new WebError(SESSION_EXPIRED_TITLE, SESSION_EXPIRED_MESSAGE);
        }

        if (response.ok === true) {
            const data = await response.json();
            return data;
        }
        throw Error(`${response.statusText} (${response.status})`);
    }

    static async patch(obj: Request) : Promise<any> {
        const { uri } = obj;
        const { body } = obj;
        const method = 'PATCH';
        const csrftoken = Cookies.get('csrftoken');

        const headers: Headers = {};
        headers.Authorization = `Token ${WebAPI.authorizationToken}`;
        headers['Content-Type'] = 'application/json';
        if (csrftoken !== undefined) {
            headers['X-CSRFTOKEN'] = csrftoken;
        }

        const response = await fetch(uri, {
            method,
            credentials: 'include',
            body: JSON.stringify(body),
            headers,
        });

        if (response.status === 403) {
            bus.$emit('forbidden');
            throw new WebError(SESSION_EXPIRED_TITLE, SESSION_EXPIRED_MESSAGE);
        }

        if (response.ok === true) {
            const data = await response.json();
            return data;
        }
        throw Error(`${response.statusText} (${response.status})`);
    }

    static async delete(obj: Request) : Promise<any> {
        const { uri } = obj;
        const method = 'DELETE';
        const csrftoken = Cookies.get('csrftoken');
        const headers: Headers = {};
        headers.Authorization = `Token ${WebAPI.authorizationToken}`;
        headers['Content-Type'] = 'application/json';
        if (csrftoken !== undefined) {
            headers['X-CSRFTOKEN'] = csrftoken;
        }

        const response = await fetch(uri, {
            method,
            credentials: 'include',
            headers,
        });

        if (response.status === 403) {
            bus.$emit('forbidden');
            throw new WebError(SESSION_EXPIRED_TITLE, SESSION_EXPIRED_MESSAGE);
        }

        if (response.ok === true) {
            const data = await response.json();
            return data || {};
        }
        throw Error(`${response.statusText} (${response.status})`);
    }

    static async upload(obj: Request) : Promise<any> {
        const { uri } = obj;
        const { body } = obj;
        const method = 'POST';
        const csrftoken = Cookies.get('csrftoken');

        const headers: Headers = {};
        headers.Authorization = `Token ${WebAPI.authorizationToken}`;
        if (csrftoken !== undefined) {
            headers['X-CSRFTOKEN'] = csrftoken;
        }

        if (!(body instanceof FormData)) {
            throw new Error('Body is not of type FormData.');
        }
        const response = await fetch(uri, {
            method,
            credentials: 'include',
            body,
            headers,
        });

        if (response.status === 403) {
            bus.$emit('forbidden');
            throw new WebError(SESSION_EXPIRED_TITLE, SESSION_EXPIRED_MESSAGE);
        }

        if (response.ok === true) {
            const data = await response.json();
            return data;
        }
        throw Error(`${response.statusText} (${response.status})`);
    }
}
