import VueRouter, { RouteConfig, Route } from 'vue-router';
import LogoutLayout from '@/layouts/LogoutLayout.vue';
import { requireUnauthenticated } from '@/utils';
import store from '@/store';
import AuthLogin from './pages/AuthLogin.vue';
import AuthLogout from './pages/AuthLogout.vue';
import AuthRegister from './pages/AuthRegister.vue';
import AuthRegisterConfirm from './pages/AuthRegisterConfirm.vue';
import AuthConfirmEmail from './pages/AuthConfirm.vue';

const routes: Array<RouteConfig> = [
    {
        path: 'register',
        name: 'auth-register',
        component: AuthRegister,
    },
    {
        name: 'auth-register-confirm',
        path: 'register-confirm',
        component: AuthRegisterConfirm,
    },
    {
        path: 'confirm/:confirmKey',
        name: 'auth-register-confirm-email',
        component: AuthConfirmEmail,
        props: true,
    },
    {
        name: 'auth-login',
        path: 'login',
        component: AuthLogin,
        // beforeEnter: requireUnauthenticated,
    },
    {
        name: 'auth-logout',
        path: 'logout',
        component: AuthLogout,
    },
];

export default routes;
