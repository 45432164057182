interface UserProfileProperties {
    jobTitle: string;
    industry?: string;
}

interface UserProfile {
    firstName: string;
    lastName: string;
    properties: UserProfileProperties;
}

class UserProfileSerializer {
    profile: UserProfile

    constructor(profile: UserProfile) {
        this.profile = profile;
    }

    public serialize() : Record<string, any> {
        const serializedData = {
            first_name: this.profile.firstName,
            last_name: this.profile.lastName,
            properties: {
                job_title: this.profile.properties.jobTitle,
                industry: this.profile.properties.industry,
            },
        };
        return serializedData;
    }
}

export {
    UserProfile,
    UserProfileSerializer,
};
