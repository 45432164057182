<template>
    <request :request="request" @on-success="onRequestSuccess">
        <v-row>
            <v-col cols=12>Report Viewer</v-col>
            <v-col cols=3>
                <report-page-list :pages="report.pages" v-if="report"  @on-page-change="changePage"/>
            </v-col>
            <v-col cols=9>
                <report-page-viewer :report-id="reportId" :page-id="selectedPageId" v-if="selectedPageId" />
            </v-col>
        </v-row>
    </request>
</template>

<script>
    import CustomReportAPI from '@/components/CustomReport/webapi';
    import ReportPageList from './ReportPageList.vue';
    import ReportPageViewer from './ReportPageViewer.vue';

    export default {
        name: 'CustomReportView',

        components: {
            ReportPageList,
            ReportPageViewer,
        },

        props: {
            reportId: {
                type: String,
                required: true,
            },
        },

        data: () => ({
            selectedPageId: undefined,
            report: undefined,
            request: undefined,
        }),

        created() {
            this.request = () => CustomReportAPI.getReport(this.reportId);
        },

        methods: {
            onRequestSuccess(data) {
                this.report = data;
            },
            changePage(pageId) {
                this.selectedPageId = pageId;
            },
        },
    };
</script>
