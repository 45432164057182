const HOST = window.location.host;
const API_BASE = `//api.${HOST}`;
const DAGSTER_BASE = `//dagster.${HOST}`;
const CSRF_TOKEN = 'csrftoken';

// eslint-disable-next-line no-shadow
export enum UserRole {
    Client = 'client',
    Staff = 'staff',
}

// eslint-disable-next-line no-shadow
export enum Apps {
    Home = 'home',
    Chat = 'chat',
    Report = 'report',
    Manage = 'manage',
    Crm = 'crm',
}

const applications = [
    {
        name: 'Home', icon: '$home', to: 'main-home', role: UserRole.Client, key: Apps.Home,
    },
    {
        name: 'Chat Analysis', icon: '$chat', to: 'chat-home', role: UserRole.Client, key: Apps.Chat,
    },
    {
        name: 'Reports', icon: 'fa-chart-line', to: 'report-home', role: UserRole.Client, key: Apps.Report,
    },
    {
        name: 'Manage', icon: 'fa-tools', to: 'manage-home', role: UserRole.Staff, key: Apps.Manage,
    },
];

export const registeredApps: Record<string, Record<string, string>> = {
    [Apps.Home]: {
        name: 'Home', icon: '$home', to: 'main-home', role: UserRole.Client, key: Apps.Home,
    },
    [Apps.Chat]: {
        name: 'Chat Analysis', icon: '$chat', to: 'chat-home', role: UserRole.Client, key: Apps.Chat,
    },
    [Apps.Crm]: {
        name: 'Hemptouch', icon: 'fa-chart-line', to: 'hemptouch-home', role: UserRole.Staff, key: Apps.Crm,
    },
    [Apps.Report]: {
        name: 'Reports', icon: 'fa-chart-line', to: 'report-home', role: UserRole.Client, key: Apps.Report,
    },
    [Apps.Manage]: {
        name: 'Manage', icon: 'fa-tools', to: 'manage-home', role: UserRole.Staff, key: Apps.Manage,
    },
};

export const EnabledApps: Record<UserRole, Array<string>> = {
    [UserRole.Staff]: ['home', 'chat', 'report', Apps.Crm],
    [UserRole.Client]: ['home', 'report'],
};

const APPLICATION_DATA = [
    {
        name: 'Home', icon: '$home', to: 'main-home', key: 'home',
    },
    {
        name: 'Reports', icon: '$view', to: 'report-home', key: 'report',
    },
    {
        name: 'Custom Reports', icon: '$view', to: 'customreport-home', key: 'customreport',
    },
    {
        name: 'HEMPTOUCH',
        kind: 'group',
        children: [
            { name: 'Home', to: 'crm-home' },
            { name: 'Customers', to: 'crm-customers' },
            { name: 'RFM Model', to: 'crm-rfm' },
        ],
        key: 'crm',
    },
    {
        name: 'MANAGE',
        kind: 'group',
        children: [
            { name: 'Home', icon: 'fa-home', to: 'manage-home' },
            { name: 'Clients', icon: 'fa-building', to: 'manage-clients' },
            { name: 'Users', icon: '$user', to: 'manage-users' },
            { name: 'Reports', icon: 'fa-building', to: 'manage-report-list' },
            { name: 'Datastore', icon: 'fa-database', to: 'manage-datastore-index' },
        ],
        key: 'manage',
    },
    {
        name: 'MODELS',
        kind: 'group',
        children: [
            { name: 'RFM', icon: 'fa-building', to: 'manage-rfm-index' },
            { name: 'Model List', icon: 'fa-building', to: 'model-list' },
        ],
        key: 'manage',
    },
    {
        name: 'TEST',
        kind: 'group',
        children: [
            { name: 'Mixpanel', icon: 'fa-building', to: 'mixpanel-playground' },
        ],
        key: 'test',
    },
    {
        name: 'ANALYTICS',
        kind: 'group',
        children: [
            { name: 'Product Analytics', icon: 'fa-building', to: 'manage-pa-index' },
        ],
        key: 'test',
    },
    {
        name: 'WORKFLOWS', icon: null, href: DAGSTER_BASE, key: 'test',
    },
];

export default {
    HOST,
    API_BASE,
    DAGSTER_BASE,
    CSRF_TOKEN,
    APPLICATION_DATA,
    applications,
};
