<template>
    <v-list class="text-left" color="transparent" dense>
        <v-subheader>PAGES</v-subheader>
        <v-list-item-group
            v-model="selectedItem"
            color="primary"
        >
            <v-list-item
                v-for="(page, i) in pages"
                :key="page.pid"
                :class="i == pageIdx ? 'v-list-item--active' : ''"
                @click="setPageId(page.pid, i)">
                {{ page.title }}
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>

<script>
    export default {
        name: 'ReportPageMenu',

        props: {
            report: {
                type: Object,
                required: true,
            },
        },

        data: () => ({
            selectedItem: 0,
        }),

        created() {
            if (this.report.pages.length > 0) {
                this.$emit('on-page-change', this.report.pages[this.selectedItem].pid);
            }
        },

        watch: {
            report: {
                immediate: false,
                handler() {
                    console.log('Report Changed');
                    this.selectedItem = 0;
                    if (this.report.pages.length > 0) {
                        this.$emit('on-page-change', this.report.pages[this.selectedItem].pid);
                    }
                },
            },
        },

        computed: {
            pages() {
                return this.report.pages;
            },
            pageIdx() {
                return this.selectedItem || 0;
            },
        },

        methods: {
            setPageId(pid, pidx) {
                this.selectedPage = pidx;
                this.$emit('on-page-change', pid);
            },
        },

    };
</script>
