<template>
    <request :request="cardsRequest" @on-success="onCardRequestSuccess">
        <v-row>
            <v-col>
                <v-row>
                    <v-col align-self="center">
                        <v-card class="pl-16 pr-16">
                            <v-card-title class="pl-16 pr-16">All your marketing data in one place.</v-card-title>
                            <v-card-text if="main-text" class="text-body1 pl-16 pr-16 text-left">
                                <p>
                                    We wanted to make it easy for you to understand your marketing data - so we put it all together.
                                </p>
                                <p>
                                    From here you can access:
                                </p>
                                <ul>
                                    <li>monthly reports</li>
                                    <li>marketing dashboards</li>
                                    <li>RFM analysis results</li>
                                    <li>[more is coming]</li>
                                </ul>
                                <p>
                                    The platform is in beta. Expect some things to move or seem unpolished.
                                </p>
                                <p>
                                    Please comment, give feedback and help us make you better.
                                </p>
                                <p>
                                    Thanks!
                                </p>

                                <p>Need help?</p>

                                <p>Message us or call: 00386 40 305 737, Domen</p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <report-view  />
            </v-col>
            <v-col cols=12>
                <v-row>
                    <v-col
                        cols=4
                        v-for="card, i in cards"
                        :key="i">
                        <v-card
                            class="mx-auto"
                            outlined
                            elevation=2
                            color="white"
                        >
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="text-overline mb-4 text-center">
                                        {{ card.name }}
                                    </div>
                                    <v-list-item-title class="text-h4 mb-1 text-center">
                                        {{ card.value }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-card-actions class="text-center">
                                <v-btn
                                    text
                                    color="primary accent-4"
                                    class="text-center"
                                    :to="card.link.to"
                                >
                                    {{ card.link.label }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </request>
</template>

<script>
    import { Apps } from '@/constants';
    import ReportView from '@/components/Report/ReportView.vue';
    import MainAPI from '../webapi';

    export default {
        name: 'MainHome',

        components: {
            ReportView,
        },

        data: () => ({
            cardsRequest: () => MainAPI.getUserCards(),
            cards: {},
        }),

        computed: {
            userHasNoProfile() {
                return this.$store.state.main.metadata.user.completed_profile !== true;
            },
            enabledApps() {
                return this.$store.getters['auth/userApps'];
            },
        },
        methods: {
            onCardRequestSuccess(data) {
                const cards = [];

                if (this.enabledApps.includes(Apps.Chat)) {
                    if (data.chat !== undefined) {
                        cards.push({
                            name: 'CHAT ANALYSIS',
                            value: data.chat.count,
                            link: {
                                to: { name: 'chat-home' },
                                label: 'See chats',
                            },
                        });
                    }
                }

                console.log('INCLUDES REPORT', this.enabledApps, this.enabledApps.includes(Apps.Report));
                if (this.enabledApps.includes(Apps.Report)) {
                    if (data.report !== undefined) {
                        cards.push({
                            name: 'REPORTS',
                            value: data.report.count,
                            link: {
                                to: { name: 'report-home' },
                                label: 'See reports',
                            },
                        });
                    }
                }

                this.cards = cards;
            },
        },
    };
</script>

<style scoped>
    #card {
        margin-top: 20px;
    }

    #main-text {
        max-width:600px;
        margin: auto;
    }
</style>
