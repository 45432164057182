<template>
    <v-row align="center" align-content="center" justify="center">
        <v-col cols=12 sm=9 md=6 lg=5 xl=3 align-self="center">
            <v-card>
                <request :request="activationRequest" @on-success="onVerifySuccess">
                    <div v-if="confirmed">
                        <v-card-title>Email confirmation was successful!</v-card-title>
                        <v-card-text class="text-left">
                            <br>
                            Your email was validated and your account is now active.
                        </v-card-text>
                        <v-card-actions>
                            <v-btn :to="{ name: 'auth-login' }">Log in</v-btn>
                        </v-card-actions>
                    </div>
                </request>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import AuthAPI from '../webapi';

    export default {
        name: 'AuthConfirmEmail',

        props: {
            confirmKey: {
                type: String,
                required: true,
            },
        },

        data: () => ({
            activationRequest: undefined,
            confirmed: false,
        }),

        created() {
            this.activationRequest = () => AuthAPI.confirmEmail(this.confirmKey);
        },

        methods: {
            onVerifySuccess(data) {
                if (data.detail === 'ok') {
                    this.confirmed = true;
                }
            },
        },
    };
</script>

<style scoped>
    .v-card__title, .v-card__subtitle {
        background: #24a575;
        color: #fff !important;
    }
</style>
