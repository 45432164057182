<template>
    <request :request="request" @on-success="onTableRequestSuccess">
        <v-card>
            <v-card-title>Reports</v-card-title>
            <v-simple-table class="text-left" v-if="hasReports">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Created at</th>
                            <th>Created By</th>
                            <th>Updated at</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in reports"
                            :key="item.id"
                            @click="$emit('click-report', item.id)"
                            style="cursor: pointer"
                        >

                            <td>{{ item.report.title }}</td>
                            <td>{{ item.created_at | datetime }}</td>
                            <td>{{ item.created_by.first_name }} {{ item.created_by.last_name }} </td>
                            <td>{{ item.updated_at | datetime }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
    </request>
</template>

<script>
    import dateutils from '@/utils/dateutils';
    import ReportAPI from './webapi';

    export default {
        name: 'ReportsTable',

        data: () => ({
            request: () => ReportAPI.listReports(),
            reports: [],
        }),

        computed: {
            hasReports() {
                return this.reports.length > 0;
            },
            userCanEdit() {
                return this.$store.getters['auth/userIsStaff'];
            },
            userCanDelete() {
                return this.$store.getters['auth/userIsStaff'];
            },
        },

        methods: {
            onTableRequestSuccess(data) {
                this.reports = data;
            },
        },

        filters: {
            datetime: dateutils.datetime,
        },
    };
</script>
