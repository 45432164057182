<template>
    <v-card>
        <v-card-title v-if="spec-title">{{ spec.title  }}</v-card-title>
        <v-simple-table class="text-left">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th v-for="(col, i) in spec.columns" :key="i">{{ col }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(row, rowIdx) in spec.rows"
                        :key="rowIdx"
                    >
                        <td v-for="(item, itemIdx) in row" :key="itemIdx">{{ item }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>
    export default {
        name: 'CustomReportComSimpleTable',

        props: {
            com: {
                type: String,
                required: true,
            },
            spec: {
                type: Object,
                required: true,
            },
        },
    };
</script>
