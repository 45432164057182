<template>
    <div>
        <v-row class="text-left">
            <v-col cols=12>
                <h3 class="grey--text">REPORT</h3>
                <h2>{{ report.title }}</h2>
            </v-col>
        </v-row>
        <v-row class="d-flex">
            <v-col cols=2>
                <page-menu :report="report" @on-page-change="onPageChange" />
            </v-col>

            <v-col cols=8>
                <page-view :page="page" v-if="page" />
            </v-col>
            <v-col cols=2>
                <comments v-if="page" :report-id="reportId" :page-id="page.pid" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import Comments from './Comments.vue';
    import PageMenu from './PageMenu.vue';
    import PageView from './PageView.vue';

    export default {
        name: 'ReportViewer',

        components: {
            Comments,
            PageMenu,
            PageView,
        },

        props: {
            reportId: {
                type: String,
                default: undefined,
            },
            reportData: {
                type: Object,
                required: true,
            },
        },

        data: () => ({
            pageShow: undefined,
        }),

        computed: {
            report() {
                return this.reportData.report;
            },
            pages() {
                if (this.reportData === undefined) {
                    return undefined;
                }

                const pages = {};

                this.report.pages.forEach((page) => {
                    pages[page.pid] = page;
                });
                return pages;
            },
            page() {
                return this.pages[this.pageShow];
            },
        },

        methods: {
            onRequestSuccess(data) {
                this.report = data.report;

                const pages = {};

                data.report.pages.forEach((page) => {
                    pages[page.pid] = page;
                });

                this.pages = pages;
            },
            onPageChange(pageId) {
                this.pageShow = pageId;
            },
        },
    };
</script>
