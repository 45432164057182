<template functional>
    <component
        :is="$options.VIcon"
        :key="data.key"
        :small="props.small"
        :left="props.left"
        :right="props.right"
        :color="props.color"
        :size="props.size"
    >
        {{ props.icon }}
    </component>
</template>

<script>
    import { VIcon } from 'vuetify/lib';

    export default {
        name: 'i-icon',
        VIcon,
        props: {
            icon: {
                type: String,
                required: true,
            },
            small: {
                type: Boolean,
                default: true,
            },
            left: {
                type: Boolean,
                default: false,
            },
            right: {
                type: Boolean,
                default: false,
            },
            color: {
                type: String,
                default: undefined,
            },
            size: {
                type: [Number, String],
                default: undefined,
            },
        },
    };
</script>
