<template>
    <div :class="{loading: loadingData}" class="text-center">
        <v-snackbar
            v-model="isThereAnError"
            bottom
            color="error"
            :timeout="-1"
        >
            <div>
                <div class="title">{{ errorTitle }}</div>
                <div>
                    {{ errorMessage }}
                </div>
            </div>
            <v-btn
                icon
                @click="isThereAnError = false"
            >
                <v-icon>mdi-close-circle</v-icon>
            </v-btn>
        </v-snackbar>
        <slot v-if="displaySlot"></slot>

        <v-progress-circular v-if="(!displaySlot) && isThereRequest"
                             indeterminate
                             color="primary"
                             class="text-center"
        />
    </div>
</template>

<script>
    const ERR = 'There is an error with a request!';
    export default {
        name: 'Request',
        props: {
            request: {
                type: Function,
            },
            errorTitle: {
                type: String,
                default: ERR,
            },
            always: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isThereAnError: false,
                errorMessage: undefined,
                loadingData: false,
                dataIsLoaded: false,
            };
        },
        computed: {
            isThereRequest() {
                return (typeof this.request !== 'undefined');
            },
            displaySlot() {
                return this.always || this.dataIsLoaded;
            },
        },
        watch: {
            request: {
                handler() {
                    if (this.isThereRequest) {
                        this.requestCall();
                    }
                },
                immediate: true,
            },
        },
        methods: {
            async requestCall() {
                try {
                    this.loadingData = true;
                    const responseData = await this.request();
                    this.dataIsLoaded = true;
                    this.$emit('on-success', responseData);
                    this.isThereAnError = false;
                } catch (error) {
                    this.errorMessage = error.message;
                    this.$emit('on-error');
                    this.isThereAnError = true;
                } finally {
                    this.loadingData = false;
                }
            },
        },
    };
</script>

<style scoped>
    .loading {
        pointer-events: none;
    }
</style>
