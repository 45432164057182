/* eslint-disable */
const fmt = (templateString: string, templateVars: Record<string, string | number>): string => {
    console.log('templatestring', templateString, 'templateVars', templateVars);
    return eval("return `" + templateString + "`;").call(templateVars);
};

//  REQUIRED
interface Params {
    errorMessage: string;
}
type RuleReturnType = ReturnType<() => boolean | string>

const required =
    (params: Params = { errorMessage: 'This field is required.' }) => (v: any): RuleReturnType => !!v || params.errorMessage;

// EMAIL
const email = (params: Params = { errorMessage: 'Email is not valid.' }) => (v: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-ignore
    return re.test(String(v).toLowerCase()) || params.errorMessage;
};


// PASSWORD
const password = (params: Params = { errorMessage: 'Password is not secure.' }) => (password: string) => {
    const MIN_LENGTH = 8;
    if (password === undefined) {
        return 'Password cannot be empty';
    }

    const errors: Array<string> = [];

    if (password.length < 8) {
        errors.push('Password needs to have at least 8 characters.');
    }

    if (/\d/.test(password) === false) {
        errors.push('Must contain a number.')
    }

    if (/[A-Z]/.test(password) === false) {
        errors.push('Must contain at least one uppercase character.')
    }

    const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    if (format.test(password) === false) {
        errors.push('Must contain at least one special chracter.')
    }

    if (errors.length === 0) {
        return true;
    } else {
        return errors;
    }
};


const containsUppercase = (params: Params = {errorMessage: 'Needs at least one uppercase character.'}) => (v: string) => {
      return /[A-Z]/.test(v) || params.errorMessage;
};

const containsSpecialCharacter = (params: Params = {errorMessage: 'Needs at least one special charcter.'}) => (v: string) => {
    const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    return format.test(v) || params.errorMessage;
};


// MINIMUM LENGTH
interface MinParams extends Params {
    threshold: number;
}

const minDefaultParameters = {
    errorMessage: 'The value has to have at least ${threshold} characters.',
    threshold: 0,
};

const min = (
    params: MinParams = {
        errorMessage: 'The value has to have at least ${threshold} characters.',
        threshold: 0,
    },
) => (v: string) => {
    const parameters = {...minDefaultParameters, ...params};
    if (v === undefined) {
        return parameters.errorMessage;
    }
    const check = v.length >= parameters.threshold;
    return check || parameters.errorMessage;
};


const rules = {
    required,
    email,
    password,
    min,
    containsUppercase,
    containsSpecialCharacter,
};

export default rules;
