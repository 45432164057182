<template>
    <v-card>
        <v-card-text>
            <v-chart

                v-if="initOptions"
                :init-options="initOptions"
                :options="spec.option" autoresize />
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: 'CustomReportComPlot',

        props: {
            com: {
                type: String,
                required: true,
            },
            spec: {
                type: Object,
                required: true,
            },
        },

        data: () => ({
            initOptions: undefined,
        }),

        created() {
            this.initOptions = {
                renderer: 'canvas',
                height: this.spec.height,
            };
        },
    };
</script>

<style scoped>
    .echarts {
        height: auto;
        width: auto;
    }
</style>
