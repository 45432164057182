<template>
    <v-row align="center" align-content="center" justify="center">
        <v-col cols=12 sm=9 md=6 lg=5 xl=3 align-self="center">
            <v-card id="login-card">
                <v-card-title color="white" class="primary white--text">
                    <v-img
                        class="mx-2"
                        src="/img/logo-white.png"
                        max-width="350"
                        contain
                    />
                </v-card-title>
                <v-card-title class="primary white--text pa-6">
                    Login
                </v-card-title>
                <v-card-text>
                    <ValidationObserver v-slot="{ invalid }">
                        <v-form @submit.prevent="login(email, password)" ref="loginForm">
                            <v-col>
                                <i-text-field v-model="email" label="Email" required email />
                            </v-col>
                            <v-col>
                                <i-text-field v-model="password" label="Password" required password />
                            </v-col>
                            <v-col>
                                <v-btn type="submit" :disabled="invalid" color="primary">Login</v-btn>
                            </v-col>
                        </v-form>
                    </ValidationObserver>
                    <v-alert
                        type="error"
                        v-if="error"
                    >
                        {{ error }}
                    </v-alert>
                    <v-col>
                        <router-link to="/auth/register">Create account</router-link>
                        <br >
                        <router-link to="/auth/change-password">Forgot password?</router-link>
                    </v-col>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';
    import WebError from '@/core/webapi/webError';

    export default {
        name: 'AuthLogin',
        components: {
            ValidationObserver,
        },
        data: () => ({
            email: undefined,
            password: undefined,
            error: undefined,
        }),
        methods: {
            login(email, password) {
                this.$store.dispatch('auth/login', { email, password })
                    .then(() => this.$router.push('/'))
                    .catch((err) => {
                        if (err instanceof WebError) {
                            this.error = err.message;
                        } else {
                            throw err;
                        }
                    });
            },
        },
    };
</script>
