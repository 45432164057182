const NOTIFY = 'NOTIFY';
const DELETE_ONE = 'DELETE_ONE';
const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS';

const MAX_DURATION = 10000; // in miliseconds
const MAX_LENGTH = 6;

type State = any;

interface Notification {
    message: string,
    props: Record<string, any>
    key: string,
}

const initialState = {
    notifications: [],
    lastNotification: undefined,
};

const purgeElementsFromQueue = (array: Array<any>, maxElements: number) => {
    const { length } = array;
    if (length > maxElements) {
        const elementsToRemove = (length - maxElements);
        array.splice(0, elementsToRemove);
    }
    return array;
};

const gettersStore = {
    length: (state: State) => state.notifications.length,
};

const actions = {
    notify(
        { commit }: { commit: Function },
        notification: Notification,
    ) {
        commit(NOTIFY, notification);
    },
    deleteone({ commit }: { commit: Function }, key: string) {
        commit(DELETE_ONE, key);
    },
    deleteall({ commit }: { commit: Function }) {
        commit(DELETE_NOTIFICATIONS);
    },
};

const mutations = {
    [NOTIFY](state: State, notification: Notification) {
        const now = Date.now();

        // Delete if notificaitons store is to old.
        // if ((now - state.lastNotification || 0) > MAX_DURATION) {
        //     state.notifications = [];
        // }

        // Purge elements from queue if queue is to long.
        // purgeElementsFromQueue(state.notifications, MAX_LENGTH);

        const notificationObject = notification;
        const i = state.notifications.length;
        notificationObject.key = `${i}-${now}`;
        state.notifications.push(notification);

        state.lastNotification = now;
    },
    [DELETE_ONE](state: State, key: string) {
        const idx = state.notifications.findIndex((n: Notification) => n.key === key);
        state.notifications.splice(idx, 1);
    },
    [DELETE_NOTIFICATIONS](state: State) {
        state.notifications = [];
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters: gettersStore,
    actions,
    mutations,
};
