import ICard from './ICard.vue';
import IDeleteBtn from './IDeleteBtn.vue';
import IIcon from './IIcon.vue';
import ISelect from './ISelect.vue';
import ISnackbar from './ISnackbar.vue';
import ITextField from './ITextField.vue';
import ITextarea from './ITextarea.vue';
import ITextFieldF from './ITextFieldFunctional.vue';

export default {
    ICard,
    IDeleteBtn,
    IIcon,
    ISelect,
    ISnackbar,
    ITextField,
    ITextarea,
    ITextFieldF,
};
