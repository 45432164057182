<template>
    <request :request="request" @on-success="onRequestSuccess">
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card color="#fafafa">
                <v-toolbar
                    dense
                    color="secondary"
                    dark
                >
                    <v-btn
                        icon
                        @click="dialog = false"
                    >
                        <v-icon>fa-times</v-icon>
                    </v-btn>
                    <v-toolbar-title><b>Report:</b> {{ title }} </v-toolbar-title>
                </v-toolbar>
                <br>
                <v-card-text>
                    <report-view :report-id="reportId" :report-data="reportData" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </request>
</template>

<script>
    import ReportAPI from '@/components/Report/Viewer/webapi';
    import ReportView from '@/components/Report/Viewer/Viewer.vue';

    export default {
        name: 'ReportBuilderPreview',

        components: {
            ReportView,
        },

        props: {
            reportId: {
                type: String,
                default: undefined,
            },
            value: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            dialog: false,
            request: undefined,
            reportData: undefined,
        }),

        computed: {
            title() {
                if (this.reportData !== undefined) {
                    return this.reportData.report.title;
                }
                return undefined;
            },
        },

        watch: {
            value: {
                immediate: true,
                handler(newValue) {
                    this.dialog = newValue;
                },
            },
            reportId: {
                immediate: true,
                handler(newValue) {
                    if (newValue !== undefined) {
                        this.request = () => ReportAPI.getReport(newValue);
                    }
                },
            },
            dialog(newValue) {
                this.$emit('input', newValue);
            },
        },

        methods: {
            onRequestSuccess(data) {
                this.reportData = data;
            },
        },
    };
</script>
