export const ACTIVATION_START = 'ACTIVATION_START';
export const ACTIVATION_CLEAR = 'ACTIVATION_CLEAR';
export const ACTIVATION_ERROR = 'ACTIVATION_ERROR';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_CLEAR = 'LOGIN_CLEAR';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const PASSWORD_EMAIL_START = 'PASSWORD_EMAIL_START';
export const PASSWORD_EMAIL_CLEAR = 'PASSWORD_EMAIL_CLEAR';
export const PASSWORD_EMAIL_ERROR = 'PASSWORD_EMAIL_ERROR';
export const PASSWORD_EMAIL_SUCCESS = 'PASSWORD_EMAIL_SUCCESS';
export const PASSWORD_RESET_START = 'PASSWORD_RESET_START';
export const PASSWORD_RESET_CLEAR = 'PASSWORD_RESET_CLEAR';
export const PASSWORD_RERET_ERROR = 'PASSWORD_RESET_ERROR';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const REGISTRATION_START = 'REGISTRATION_START';
export const REGISTRATION_CLEAR = 'REGISTRATION_CLEAR';
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const SET_TOKEN = 'SET_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
