<template>
    <v-card>
        <v-card-title>Pages</v-card-title>
        <v-simple-table class="text-left">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th/>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="page in pages"
                        :key="page.key"
                        @click="viewPage(page.key)"
                        :class="{'active':page.key === pageKey}"
                    >
                        <td>
                            <b>{{ page.name }}</b>
                            <br>
                            <small>{{ page.desc  }}</small>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>
    export default {
        name: 'CustomReportPageList',

        props: {
            pages: Array,
            default: () => [],
        },

        data: () => ({
            pageKey: undefined,
        }),

        methods: {
            viewPage(pageKey) {
                this.pageKey = pageKey;
                this.$emit('on-page-change', pageKey);
            },
        },
    };
</script>
