import Vue from 'vue';
import dayjs from 'dayjs';
import Calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.extend(Calendar);

function toTitleCase(str: String) {
    return str.replace('_', ' ').replace(
        /\w\S*/g,
        (txt: String) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    );
}

function currency(value: number, params: Record<any, any>) {
    const locale = params?.locale || 'sl-SI';

    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return formatter.format(value);
}

Vue.filter('currency', currency);
Vue.filter('localDateTime', (dateTime: string) => dayjs(dateTime));
Vue.filter('date', (dateTime: string) => dayjs(dateTime).format('DD MMM YYYY'));
Vue.filter('fromNow', (dateTime: string) => dayjs(dateTime).fromNow());
Vue.filter('snakeToTitleCase', toTitleCase);
