<template>
    <span>
        <slot v-if="displayPii"></slot>
        <span v-else>{{ fakeValue }}</span>
    </span>
</template>

<script>
    export default {
        name: 'Pii',

        props: {
            kind: {
                type: String,
                default: undefined,
            },
        },

        computed: {
            fakeValue() {
                if (this.kind === undefined) {
                    return '<Hidden>';
                }
                return `<Hidden ${this.kind}>`;
            },
            displayPii() {
                return this.$store.getters['core/piiEnabled'];
            },
        },
    };
</script>
