<template>
    <v-card class="text-center">
        <v-card-text>
            <v-row>
                <v-col class="text-h4 font-weight-bold" cols=12>{{ spec.value }}</v-col>
                <v-col class="font-weight" cols=12>{{ spec.label }}</v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: 'CustomReportComMetric',

        props: {
            com: {
                type: String,
                required: true,
            },
            spec: {
                type: Object,
                required: true,
            },
        },
    };
</script>
