import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import Layout from '@/Layout.vue';
import LogoutLayout from '@/layouts/LogoutLayout.vue';
import authRoutes from './apps/auth/routes';
import chatAnalysisRoutes from './apps/chat-analysis/routes';
import mainRoutes from './apps/main/routes';
import manageRoutes from './apps/manage/routes';
import reportRoutes from './apps/report/routes';
import crmRoutes from './apps/crm/routes';
import customReportRoutes from './apps/customreports/routes';
// import state from './store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/auth',
        component: LogoutLayout,
        children: [
            ...authRoutes,
        ],
    },
    {
        path: '',
        name: 'index',
        component: Layout,
        // redirect: { name: 'auth-register' },
        children: [
            ...chatAnalysisRoutes,
            // ...crmRoutes,
            ...mainRoutes,
            ...manageRoutes,
            ...reportRoutes,
            ...customReportRoutes,
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to: Route, from: Route, next: Function) => {
    console.log('Navigate To', to);
    window.dataLayer.push({
        event: 'pa_track_page_view',
        name: 'page_view',
        properties: {
            pa_track_page_view: {
                full_path: to.fullPath,
                path_name: to.name,
            },
        },
    }); // @ts-ignore
    next();
});

// router.beforeEach((to: Route, from: Route, next: Function) => {
//     // Every user has to have a valid user profile.
//     const ONBOARDING_ROUTE_NAME = 'main-home';
//     if (
//         state.getters['auth/isAuthenticated'] === true
//         && state.getters['auth/hasUserProfile'] === false
//         && to.name !== ONBOARDING_ROUTE_NAME
//     ) {
//         next({ name: ONBOARDING_ROUTE_NAME });
//     } else {
//         next();
//     }
// });

export default router;
