<template>
    <span>
        <v-btn
            @click.stop="dialog = true"
            small
            icon
        >
            <v-icon small>$delete</v-icon>
        </v-btn>

        <v-dialog
            v-model="dialog"
            max-width="500"
        >
            <v-card>
                <v-card-title class="headline">{{ title }} </v-card-title>

                <v-card-text v-if="text">
                    {{ text }}
                    <v-spacer />
                    <div v-if="confirm">
                        To confirm type the bottom number <br>
                        <strong>{{ randomNumber }}</strong>

                        <i-text-field v-model="confirmNumber" />
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />

                    <v-btn
                        color="red darken-1"
                        @click="dialog = false"
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="agree"
                    >
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
    export default {
        name: 'IDeleteBtn',
        props: {
            title: {
                type: String,
                required: true,
            },
            text: {
                type: String,
                default: undefined,
            },
            confirm: {
                type: Boolean,
                default: false,
            },
            payload: {
                type: Object,
                default: undefined,
            },
        },
        data() {
            return {
                dialog: false,
                randomNumber: undefined,
                confirmNumber: undefined,
            };
        },
        computed: {
            isConfirmed() {
                if (this.confirm === true) {
                    if (this.randomNumber === parseInt(this.confirmNumber, 10)) {
                        return true;
                    }
                    return false;
                }
                return true;
            },
        },
        watch: {
            dialog() {
                if (this.dialog === true) {
                    this.randomNumber = this.getRandomNumber(123, 987);
                }
                this.confirmNumber = undefined;
            },
        },
        methods: {
            agree() {
                if (this.isConfirmed === true) {
                    this.dialog = false;
                    this.errorMessage = undefined;
                    this.$emit('on-confirm', this.payload);
                } else {
                    this.errorMessage = 'Number is not correct';
                }
            },
            getRandomNumber(min, max) {
                return Math.floor(Math.random() * (max - min)) + min;
            },
        },
    };
</script>

<style scoped>
    .v-input {}
</style>
