<template functional>
    <component
        :is="$options.VCard"
        :key="data.key"
        :flat="props.flat"
        :outlined="props.outlined"
        :disabled="props.disabled"
        :color="props.color"
        :width="props.width"
        :max-width="props.maxWidth"
        :min-width="props.minWidth"
        :height="props.height"
        :max-height="props.maxHeight"
        :min-height="props.minHeight"
    >
        <slot></slot>
    </component>
</template>

<script>
    import { VCard } from 'vuetify/lib';

    export default {
        name: 's-card',
        VCard,
        props: {
            flat: {
                type: Boolean,
                default: false,
            },
            outlined: {
                type: Boolean,
                default: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            color: {
                type: String,
                default: undefined,
            },
            width: {
                type: [String, Number],
                default: undefined,
            },
            maxWidth: {
                type: [String, Number],
                default: undefined,
            },
            minWidth: {
                type: [String, Number],
                default: undefined,
            },
            height: {
                type: [String, Number],
                default: undefined,
            },
            maxHeight: {
                type: [String, Number],
                default: undefined,
            },
            minHeight: {
                type: [String, Number],
                default: undefined,
            },
        },
    };
</script>
