export type errorObject = {
    title: string,
    message: string,
};

const ERROR_DATA = {
    title: 'Whoops! Something went wrong!',
    message: 'Don\'t worry. We were notified about the issue.',
};

export default class WebapiError extends Error {
    error: errorObject;

    protected constructor(error?: errorObject) {
        super();
        this.error = { ...ERROR_DATA, ...error };
        this.name = 'WebapiError';
        this.message = `${this.error.title}: ${this.error.message}`;
        Object.setPrototypeOf(this, WebapiError.prototype);
    }
}
