<template>
    <v-row class="text-left">
        <v-col cols=12>
            <label>Filter</label>
            <i-text-field v-model="search" />
        </v-col>
        <v-col cols=12>
            <v-card v-for="(item, i) in items" :key="i" class="mt-2">
                <v-card-text>{{ item.text  }}</v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'CustomReportComSimpleList',

        props: {
            com: {
                type: String,
                required: true,
            },
            spec: {
                type: Object,
                required: true,
            },
        },

        data: () => ({
            search: undefined,
        }),

        computed: {
            items() {
                if (!this.search) {
                    return this.spec.items;
                }
                const searchTokens = this.search.split(' ');
                return this.spec.items.filter((item) => {
                    const text = item.text.toLowerCase();
                    let match = false;
                    searchTokens.forEach((token) => {
                        if (text.includes(token.toLowerCase())) {
                            match = true;
                        }
                    });
                    return match;
                });
            },
        },
    };
</script>
