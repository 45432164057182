export const NO_NAME_ASSIGNED = 'NO_NAME_ASSIGNED';

export function getAttr(attributes: object | undefined, attr: string) {
    if (!attributes) return false;
    if (Object.prototype.hasOwnProperty.call(attributes, attr)) {
        return attributes[(attr as keyof Object)];
    }
    return false;
}

const RULES: string[] = [
    'required',
    'email',
    'min',
];

export function getValidationRules(vueProps: any) {
    // Generates VeeValidate rules from vueProps.
    // For use in functional components.
    const rules: string[] = [];
    RULES.forEach((el) => (vueProps[el] ? rules.push(el) : undefined));
    return rules.join('|');
}
