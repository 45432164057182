<template>
    <v-row align="center" align-content="center" justify="center">
        <v-col cols=12 sm=9 md=6 lg=5 xl=3 align-self="center">
            <v-card id="login-card">
                <v-card-title color="white" class="primary white--text">
                    <v-img
                        class="mx-2"
                        src="/img/logo-white.png"
                        max-width="350"
                        contain
                    />
                </v-card-title>
                <v-card-title class="primary white--text pa-6">
                    Logged Out
                </v-card-title>
                <v-card-text>
                    <div v-if="loggedOut" class="text-subheader mt-6">
                        <div v-if="expired">
                            Your session expired. Please log in again.
                        </div>
                        <div v-else>
                            You sucessfuly logged out.
                        </div>
                    </div>
                    <v-btn color="primary" class="mt-6" href="/auth/login">Log In</v-btn>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'AuthLogout',

        props: {
            expired: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            loggedOut: false,
        }),

        mounted() {
            this.$store.dispatch('auth/logout').then(() => {
                this.loggedOut = true;
            });
        },

        methods: {
            logout() {
                this.$store.dispatch('auth/logout').then(() => {
                    this.loggedOut = true;
                });
            },
        },
    };
</script>
