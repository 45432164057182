import { Route } from 'vue-router';
import { UserRole } from '../constants';
import store from '../store';
import rules from './validation';
import multiguard from './guards';

const requireAuthenticated = (to: Route, from: Route, next: Function) => {
    store.dispatch('auth/initialize')
        .then(() => {
            if (!store.getters['auth/userIsLoggedIn']) {
                console.log('GUARD -<> Require Authenticaterd -> Not Authenticated');
                next('/auth/login');
            } else {
                console.log('GUARD -> Require Authenticated -> OK Authenticated');
                next();
            }
        });
};

const requireUnauthenticated = (to: Route, from: Route, next: Function) => {
    store.dispatch('auth/initialize')
        .then(() => {
            if (store.getters['auth/userIsLoggedIn']) {
                console.log('USEEEEEEERR IS LOGED IN');
                next('/');
            } else {
                next();
            }
        });
};

const requireStaff = (to: Route, from: Route, next: Function) => {
    if (store.getters['auth/isAuthenticated'] && store.getters['auth/userIsStaff']) {
        next();
    } else {
        next('/');
    }
};

const requireUserRole = (role: UserRole) => (to: Route, from: Route, next: Function) => {
    const userRole = store.getters['auth/userRoleKey'];
    store.dispatch('auth/initMeta').then(() => {
        console.log('GUARD -> USER ROLE -> Mta is set', store.getters['auth/metaIsReady']);
        console.log('GUARD -> Require User Role ->', userRole, role);
        if (userRole === role) {
            next();
        } else {
            next('/');
        }
    });
};

function debounce<T extends Function>(cb: T, wait = 20) {
    let h = 0;
    const callable = (...args: any) => {
        clearTimeout(h);
        h = setTimeout(() => cb(...args), wait);
    };
    return <T>(<any>callable);
}

function flattenObject(ob: Record<string, any>) : Object {
    const toReturn: Record<string, any> = {};

    Object.keys(ob).forEach((i) => {
        if (ob[i].constructor === Object) {
            const flatObject: Record<string, any> = flattenObject(ob[i]);
            Object.keys(flatObject).forEach((x) => {
                toReturn[`${i}.${x}`] = flatObject[x];
            });
        } else {
            toReturn[i] = ob[i];
        }
    });
    return toReturn;
}

export {
    debounce,
    flattenObject,
    requireAuthenticated,
    requireStaff,
    requireUnauthenticated,
    requireUserRole,
    rules,
    multiguard,
};
