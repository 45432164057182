<template>
    <request :request="request" @on-success="reportRequestSuccess">
        <report-viewer :report-id="reportId" :report-data="reportData" />
    </request>
</template>

<script>
    import ReportAPI from '@/components/Report/Viewer/webapi';
    import ReportViewer from '@/components/Report/Viewer/Viewer.vue';

    export default {
        name: 'ReportViewPage',

        components: {
            ReportViewer,
        },

        props: {
            reportId: {
                type: String,
                required: true,
            },
        },

        data: () => ({
            reportData: undefined,
        }),

        computed: {
            request() {
                return () => ReportAPI.getReport(this.reportId);
            },
        },

        methods: {
            reportRequestSuccess(data) {
                this.reportData = data;
                console.log('[apps/report/pages/ReportView.vue] --> set data', { data, reportId: this.reportId });
            },
        },
    };
</script>
