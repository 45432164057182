<template>
    <div>
        <platform-bar :applications="applications" @drawer-click="drawerClick"/>

        <div v-if="userIsLoggedIn && userHasNoProfile">
            <v-main>
                <v-container>
                    <v-row>
                        <v-col class="ma-10">
                            <onboarding />
                        </v-col>
                    </v-row>
                </v-container>
            </v-main>
        </div>
        <div v-else>
            <v-app-bar
                app
                dense
                elevation=0
                clipped-right
                clipped-left
                color="white"
            >
                <v-toolbar-title class="" v-if="$route.meta && $route.meta.app">
                    <slot name="app-bar-title"><v-icon size="normal">{{ $route.meta.app.icon }} </v-icon> {{ $route.meta.app.name }}</slot>
                </v-toolbar-title>
                <v-spacer />
                <slot name="app-bar-actions"></slot>
            </v-app-bar>
            <navigation-drawer v-model="leftDrawer" app left clipped :enabled-applications="applications" />
            <v-main max-width=1200>
                <i-breadcrumbs :items="breadcrumbItems" />
                <v-container
                    max-width="1200"
                    class="align-start ma-120 pa-300"
                    fluid
                >
                    <router-view :key="$route.path" />
                </v-container>
            </v-main>
        </div>
    </div>
</template>

<script>
    import PlatformBar from '@/core/shell/PlatformBar.vue';
    import IBreadcrumbs from '@/core/ds/IBreadcrumbs.vue';
    import Onboarding from '@/apps/main/components/Onboarding.vue';
    // import FacebookMessenger from '@/components/FacebookMessenger.vue';
    import NavigationDrawer from '@/components/NavigationDrawer.vue';

    export default {
        name: 'MainLayout',
        components: {
            // FacebookMessenger,
            PlatformBar,
            IBreadcrumbs,
            Onboarding,
            NavigationDrawer,
        },

        props: {
            leftItems: {
                type: Array,
                default: () => [],
            },
        },

        data: () => ({
            leftDrawer: true,
            breadcrumbItems: [],
        }),

        computed: {
            applications() {
                return this.$store.getters['auth/enabledApplications'];
            },
            userIsLoggedIn() {
                return this.$store.getters['auth/userIsLoggedIn'];
            },
            userHasNoProfile() {
                return !this.$store.getters['auth/hasUserProfile'];
            },
            userIsStaff() {
                return this.$store.getters['auth/userIsStaff'];
            },
            metaIsReady() {
                return this.$store.getters['auth/metaIsReady'];
            },

        },
        watch: {
            $route: {
                immediate: true,
                handler() {
                    this.breadcrumbItems = this.$route.meta.breadcrumbs(this.$route);
                },
            },
        },

        methods: {
            drawerClick() {
                this.leftDrawer = !this.leftDrawer;
            },
        },
    };
</script>

<style scoped>
    .v-main {
        padding-top: 116px !important;
    }
    .v-app-bar.white {
        margin-top: 68px !important;
        border-bottom: 1px solid #dedede !important;
    }
    .v-navigation-drawer {
        top: 116px !important;
    }
</style>
