<template>
    <p class="text-left">{{ spec.content }}</p>
</template>

<script>
    export default {
        name: 'CustomReportComParagraph',

        props: {
            com: {
                type: String,
                required: true,
            },
            spec: {
                type: Object,
                required: true,
            },
        },
    };
</script>
