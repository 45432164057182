<template>
    <div class="vue-friendly-iframe">
        <iframe
            ref="chartiframe"
            id="datawrapper-chart-8dfPN"
            :src="src"
            scrolling="no"
            frameborder="0"
            allowtransparency="true"
            :style="iframeStyle"
            :height="iframeHeight" />
    </div>
</template>

<script>
    /* eslint-disable */
    const DELTAS = {
        100: 481,
        200: 427,
        300: 800,
        400: 800,
        500: 800,
        700: 800,
        800: 780,
        900: 850,
        1000: 800,
    };

    export default {
        data() {
            return {
                iframeHeight: '0px', // initial height
                iframeStyle: '',
            };
        },
        props: {
            src: {
                type: String,
                required: true
            },
            crossorigin: {
                type: String,
                required: false,
                default: 'anonymous'
            },
            target: {
                type: String,
                required: false,
                default: '_parent'
            },
            className: {
                type: String,
                required: false
            },
            allow: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: false
            },
            title: {
                type: String,
                required: false
            },
            sandbox: {
                type: String,
                required: false
            },
            width: {
                type: Number,
                default: 1200,
            },
            height: {
                type: Number,
                default: 900,
            },
            paddingBottom: {
                type: Number,
                default: 0,
            },
        },
        computed: {
            // Necessary for initial iframe height
            iframeOffsetWidth() {
                const w = this.$refs.chartiframe.offsetWidth;
                console.log('chartiframe ref', this.$refs.chartiframe);
                console.log('iframeOffsetWidth', w);
                return w;
            },
        },
        mounted() {
            // set initial iframe height
            this.iframeHeight = `${DELTAS[Math.min(1e3,Math.max(100*Math.floor(this.iframeOffsetWidth/100),100))]}px`;
            this.iframeStyle = `width: 100%; !important; max-width: ${this.width}px`;
            // setup event listener
            window.addEventListener('message', this.handleIframeResize);
        },
        beforeDestroy() {
            // destroy event listener
            window.removeEventListener('message', this.handleIframeResize);
        },
        methods: {
            handleIframeResize(e) {
                const ratio = this.height / this.width;
                const height = Math.floor((this.$refs.chartiframe.offsetWidth * ratio) + this.paddingBottom);
                this.iframeHeight = `${height}px`;
                // this.iframeHeight = `${DELTAS[Math.min(1e3,Math.max(100*Math.floor(this.iframeOffsetWidth/100),100))]}px`;
            },
        },
    };
</script>
