import { registeredApps, UserRole } from '@/constants';
import AuthAPI from './webapi';
import utils from './utils';

import {
    ACTIVATION_CLEAR,
    ACTIVATION_START,
    ACTIVATION_ERROR,
    ACTIVATION_SUCCESS,
    REGISTRATION_CLEAR,
    REGISTRATION_START,
    REGISTRATION_ERROR,
    REGISTRATION_SUCCESS,
    LOGIN_START,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOGOUT,
    REMOVE_TOKEN,
    SET_TOKEN,
} from './storeconstants';

declare global {
    interface window {}
}

const corewebapi = AuthAPI.webapi;

type State = any;
type Metadata = Record<string, any>;

const METADATA_SET = 'METADATA_SET';
const TOKEN_STORAGE_KEY = 'TOKEN_STORAGE_KEY';
const isProduction = process.env.NODE_ENV === 'production';

const initialState = {
    isAuthenticating: false,
    error: false,
    token: null,
    activationCompleted: false,
    activationError: false,
    activationLoading: false,
    registrationCompleted: false,
    registrationError: false,
    registrationLoading: false,
    metadata: undefined,
};

const getters = {
    isAuthenticated: (state: State) => !!state.token,
    metaIsReady: (state: State) => state.metadata !== undefined,
    user: (state: State) => state.metadata?.user,
    hasUserProfile: (state: State) => {
        const userState = state.metadata?.user || {};
        return userState.completed_profile === true;
    },
    userRoleKey: (state: State) => (state.metadata ? state.metadata.user.role.key : undefined),
    userIsStaff: (state: State) => state.metadata.user.role.key === UserRole.Staff,
    userIsLoggedIn: (state: State) => (
        state.metadata !== undefined
        && state.metadata.user
        && !!state.token
    ),
    userApps: (state: State) => (state.metadata ? state.metadata.apps : []),
    enabledApplications: (state: State) => {
        if (state.metadata !== undefined) {
            return state.metadata.apps;
        }
        return [];
    },
};

const actions = {
    metadata({ commit }: { commit: Function }, metadata: Metadata) {
        commit(METADATA_SET, metadata);
        if (window !== undefined && window.dataLayer !== undefined && metadata?.user) {
            window.dataLayer.push({ event: 'uidAvailable', uid: metadata.user.id, email: metadata.user.email }); // @ts-ignore
            window.dataLayer.push({
                event: 'pa_user_identify',
                name: 'login',
                properties: {
                    pa_user_identify: {
                        first_name: metadata.user.first_name,
                        last_name: metadata.user.last_name,
                        name: `${metadata.user.first_name} ${metadata.user.last_name}`,
                        email: metadata.user.email,
                        role: metadata.user.role.key,
                    },
                },
            });
            window.dataLayer.push({
                event: 'pa_track_login',
                name: 'login',
                properties: {
                    pa_track_login: {
                        email: metadata.user.email,
                    },
                },
            });
        }
    },
    refreshMetadata: {
        root: true,
        async handler({ dispatch }: { commit: Function, dispatch: Function }) {
            const metadata = await AuthAPI.getMetadata();
            dispatch('metadata', metadata);
        },
    },
    async login(
        { commit, dispatch }: { commit: Function, dispatch: Function },
        { email, password }: {email: string, password: string},
    ) {
        commit(LOGIN_START);
        return AuthAPI.login(email, password)
            .then((data) => {
                commit(SET_TOKEN, data.key);
            })
            .then(() => dispatch('refreshMetadata', {}, { root: true }))
            .then(() => commit(LOGIN_SUCCESS))
            .catch((error) => commit(LOGIN_ERROR, error));
    },
    logout({ commit }: { commit: Function }) {
        return AuthAPI.logout()
            .then(() => commit(LOGOUT))
            .then(() => commit(REMOVE_TOKEN));
    },
    async initialize(
        { commit, dispatch, rootGetters }:
        { commit: Function, dispatch: Function, rootGetters: any, },
    ) {
        const token = localStorage.getItem(TOKEN_STORAGE_KEY);

        // if (isProduction && token) {
        //     console.log("IS_PRODUCTIO")
        //     commit(REMOVE_TOKEN);
        // }
        if (token && token !== 'undefined') {
            commit(SET_TOKEN, token);
        }

        if (rootGetters['auth/metaIsReady'] === false) {
            await dispatch('refreshMetadata', {}, { root: true });
        }
    },

    initMeta({ dispatch, rootGetters }: { dispatch: Function, rootGetters: any }) {
        if (rootGetters['auth/metaIsReady'] === false) {
            dispatch('refreshMetadata', {}, { root: true });
        }
    },

    // REGISTRATION
    createAccount(
        { commit }: {commit: Function},
        {
            email, password1, password2,
        }:
        {
            email: string,
            password1: string,
            password2: string,
        },
    ) {
        // commit(REGISTRATION_START);
        return AuthAPI.register(email, password1, password2);
    },
};

const mutations = {
    [LOGIN_START](state: State) {
        state.authenticating = true;
        state.error = false;
    },
    [LOGIN_ERROR](state: State, error: any) {
        state.authenticating = false;
        state.error = true;
        throw error;
    },
    [LOGIN_SUCCESS](state: State) {
        state.authenticating = false;
        state.error = false;
    },
    [LOGOUT](state: State) {
        state.authenticating = false;
        state.error = false;
        state.metadata = undefined;
    },
    [SET_TOKEN](state: State, token: string) {
        const tokenKey = token;
        localStorage.setItem(TOKEN_STORAGE_KEY, tokenKey);
        corewebapi.authorizationToken = tokenKey;
        state.token = tokenKey;
    },
    [REMOVE_TOKEN](state: State) {
        localStorage.removeItem(TOKEN_STORAGE_KEY);
        corewebapi.authorizationToken = null;
        state.token = null;
    },
    [REGISTRATION_START](state: State) {
        state.regsitrationLoading = true;
    },
    [REGISTRATION_CLEAR](state: State) {
        state.registrationCompleted = false;
        state.registrationError = false;
        state.registrationLoading = false;
    },
    [REGISTRATION_ERROR](state: State) {
        state.registrationError = true;
        state.registrationLoading = true;
    },
    [REGISTRATION_SUCCESS](state: State) {
        state.registrationCompleted = true;
        state.registrationError = false;
        state.registrationLoading = true;
    },
    [METADATA_SET](state: State, metadata: Record<string, any>) {
        state.metadata = metadata;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
