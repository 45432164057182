import store from '../store';

type Props = Record<string, any>;

const notify = (message: string, props: Record<string, any>) => {
    const ensureProps = {
        right: true,
        bottom: true,
        color: 'blue',
        ...props,
    };

    const notification = { message, ...ensureProps };
    store.dispatch('notifications/notify', notification);
};

const notifyError = (message: string) => {
    notify(message, { color: 'error' });
};

const notifySuccess = (message: string) => {
    notify(message, { color: 'success' });
};

const notifyInfo = (message: string) => {
    notify(message, { color: 'blue' });
};

const notifyDelete = (key: string) => {
    store.dispatch('notifications/deleteone', key);
};

const helpers = {
    notify,
    notifyDelete,
    notifyError,
    notifySuccess,
    notifyInfo,
};

const plugin = {
    install(Vue: any, options: any) {
        Vue.helpers = helpers; //eslint-disable-line
        Vue.prototype.$helpers = helpers; //eslint-disable-line
    },
};

export default plugin;
