<template>
    <div v-html="text" />
</template>

<script>
    import marked from 'marked';

    export default {
        name: 'RText',

        props: {
            spec: {
                type: Object,
                required: true,
            },
        },

        computed: {
            text() {
                return marked(this.spec.text);
            },
        },
    };
</script>
