import VueRouter, { Route, RouteConfig } from 'vue-router';
import { UserRole } from '@/constants';
import state from '@/store';
// import Layout from '@/Layout.vue';
import { requireAuthenticated, requireUserRole, multiguard } from '@/utils';
// import Layout from './Layout.vue';

const APP_ICON = 'fa-chart-line';
const APP_NAME = 'Manage';
const app = { name: APP_NAME, icon: APP_ICON };

// const ROUTE_MANAGE_HOME = 'manage-home';
// const ROUTE_MANAGE_CLIENTS = 'manage-clients';
// const ROUTE_MANAGE_USERS = 'manage-users';
// const ROUTE_MANAGE_REPORT_LIST = 'manage-report-list';
// const ROUTE_MANAGE_REPORT_BUILDER_NEW = 'manage-report-builder-new';
// const ROUTE_MANAGE_REPORT_BUILDER = 'manage-repoort-builder-new';

// Route guard for every route.
const routeGuard = multiguard([
    requireAuthenticated,
    requireUserRole(UserRole.Staff),
]);

const loadPage = (page: string) => () => import(/* webpackChunkName: 'app-manage' */ `./pages/${page}.vue`);

const buildRoute = (
    path: string,
    name: string,
    page: string,
    params: Record<string, any>,
    children: Array<any>,
    breadcrumbs: Array<Record<string, any>>,
) => {
    const breadcrumbsCallable = () => breadcrumbs;
    return {
        path: `manage/${path}`,
        name,
        component: loadPage(page),
        meta: { app, breadcrumbs: breadcrumbsCallable },
        beforeEnter: routeGuard,
        children,
        props: true,
        ...params,
    };
};

const routes: Array<RouteConfig> = [

    buildRoute('home', 'manage-home', 'HomePage', {}, [], [
        {
            disabled: true, exact: true, link: true, text: 'Manage', to: { name: 'manage-home' },
        },
    ]),
    buildRoute('clients', 'manage-clients', 'ClientManage', {}, [], [
        {
            disabled: false, exact: true, link: true, text: 'Manage', to: { name: 'manage-home' },
        },
        {
            disabled: true, exact: true, link: true, text: 'Clients', to: { name: 'manage-clients' },
        },
    ]),
    buildRoute('users', 'manage-users', 'UserManage', {}, [], [
        {
            disabled: false, exact: true, link: true, text: 'Manage', to: { name: 'manage-home' },
        },
        {
            disabled: true, exact: true, link: true, text: 'Users', to: { name: 'manage-users' },
        },
    ]),
    buildRoute('users/single/:userId', 'manage-users-single', 'UserManageSingle', { props: true }, [], [
        {
            disabled: false, exact: true, link: true, text: 'Manage', to: { name: 'manage-home' },
        },
        {
            disabled: false, exact: true, link: true, text: 'Users', to: { name: 'manage-users' },
        },
        {
            disabled: true, exact: true, link: true, text: 'User', to: { name: 'manage-users-single' },
        },
    ]),
    buildRoute('report/list', 'manage-report-list', 'ReportList', {}, [], [
        {
            disabled: false, exact: true, link: true, text: 'Manage', to: { name: 'manage-home' },
        },
        {
            disabled: true, exact: true, link: true, text: 'Report', to: { name: 'manage-report-list' },
        },
    ]),
    buildRoute('report/builder', 'manage-report-builder-new', 'ReportBuilder', { props: true }, [], [
        {
            disabled: false, exact: true, link: true, text: 'Manage', to: { name: 'manage-home' },
        },
        {
            disabled: false, exact: true, link: true, text: 'Report', to: { name: 'manage-report-list' },
        },
        {
            disabled: true,
            exact: true,
            link: true,
            text: 'Create',
            to: { name: 'manage-report-builder-new' },
        },
    ]),
    buildRoute('report/builder/:reportId', 'manage-report-builder', 'ReportBuilder', { props: true }, [], [
        {
            disabled: false, exact: true, link: true, text: 'Manage', to: { name: 'manage-home' },
        },
        {
            disabled: false, exact: true, link: true, text: 'Report', to: { name: 'manage-report-list' },
        },
        {
            disabled: true,
            exact: true,
            link: true,
            text: 'Edit',
            to: { name: 'manage-report-builder' },
        },
    ]),
    // DATASTORE ROUTES
    buildRoute('datastore', 'manage-datastore-index', 'Datastore', { props: true }, [], [
        {
            disabled: false, exact: true, link: true, text: 'Manage', to: { name: 'manage-home' },
        },
        {
            disabled: true,
            exact: true,
            link: true,
            text: 'Datastore',
            to: { name: 'manage-datastore-index' },
        },
    ]),
    buildRoute('datastore/:datasetId', 'manage-datastore-view', 'Datastore/Viewer', { props: true }, [], [
        {
            disabled: false, exact: true, link: true, text: 'Manage', to: { name: 'manage-home' },
        },
        {
            disabled: false,
            exact: true,
            link: true,
            text: 'Datastore',
            to: { name: 'manage-datastore-index' },
        },
        {
            disabled: true,
            exact: true,
            link: true,
            text: 'Dataset',
            to: { name: 'manage-datastore-view' },
        },
    ]),
    // RFM MODEL
    buildRoute('rfm', 'manage-rfm-index', 'RfmModel', { props: true }, [], [
        {
            disabled: false, exact: true, link: true, text: 'Manage', to: { name: 'manage-home' },
        },
        {
            disabled: true,
            exact: true,
            link: true,
            text: 'RFM Model',
            to: { name: 'manage-rfm-index' },
        },
    ]),
    // PRODUCT ANALYTICS
    buildRoute('analytics/product-analytics', 'manage-pa-index', 'ProductAnalyticsIndex', { props: true }, [], [
        {
            disabled: false, exact: true, link: true, text: 'Manage', to: { name: 'manage-home' },
        },
        {
            disabled: true,
            exact: true,
            link: true,
            text: 'Product Analytics',
            to: { name: 'manage-pa-index' },
        },
    ]),
    buildRoute('analytics/product-analytics/:reportKey', 'manage-pa-report', 'ProductAnalyticsReport', { props: true }, [], [
        {
            disabled: false, exact: true, link: true, text: 'Manage', to: { name: 'manage-home' },
        },
        {
            disabled: false,
            exact: true,
            link: true,
            text: 'Product Analytics',
            to: { name: 'manage-pa-index' },
        },
        {
            disabled: false,
            exact: true,
            link: true,
            text: 'Product Analytics',
            to: { name: 'manage-pa-report' },
        },
    ]),
    // MIXPANEL PLAYGROUND
    buildRoute('mixpanel', 'mixpanel-playground', 'MixpanelPlayground', { props: true }, [], [
        {
            disabled: false, exact: true, link: true, text: 'Manage', to: { name: 'manage-home' },
        },
        {
            disabled: true,
            exact: true,
            link: true,
            text: 'Mixpanel Playground',
            to: { name: 'mixpanel-playground' },
        },
    ]),
    buildRoute('modellist', 'model-list', 'ModelList/ModelList', { props: true }, [], []),
];

// EXPORT
export default routes;
