<template>
    <div class="text-center iframe-container">
        <custom-iframe :src="src" :width="width" :height="height" :padding-bottom="paddingBottom" />
    </div>
</template>

<script>
    import CustomIframe from '@/components/CustomIframe.vue';

    export default {
        name: 'RIframe',

        components: {
            CustomIframe,
        },

        props: {
            spec: {
                type: Object,
                required: true,
            },
        },

        computed: {
            src() {
                return this.spec.src;
            },
            width() {
                return parseInt(this.spec.width, 10) || 1200;
            },
            height() {
                return parseInt(this.spec.height, 10) || 950;
            },
            paddingBottom() {
                return parseInt(this.spec.paddingb, 10) || 0;
            },
        },
    };
</script>

<style scoped>

</style>
