type State = any;

const initialState = {
    pii: false,
};

const gettersStore = {
    piiEnabled: (state: State) => state.pii,
};

const mutations = {
    pii(state: State, enabled: Boolean) {
        state.pii = enabled;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters: gettersStore,
    mutations,
};
