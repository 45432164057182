<template>
    <request :request="request" @on-success="onRequestSuccess">
        <v-row>
            <v-col v-if="page" col=12>
                <component
                    v-for="com in page.components"
                    v-bind:is="getComponent(com.component)"
                    class="mb-4"
                    :spec="com.data"
                    :key="com.uid"
                    :com="com.component" />

            </v-col>
        </v-row>
    </request>
</template>

<script>
    import CustomReportAPI from '@/components/CustomReport/webapi';
    import Title from './Com/Title.vue';
    import Metric from './Com/Metric.vue';
    import Plot from './Com/Plot.vue';
    import SimpleList from './Com/SimpleList.vue';
    import Paragraph from './Com/Paragraph.vue';
    import SimpleTable from './Com/SimpleTable.vue';

    const components = {
        h2: 'Title',
        h3: 'Title',
        Metric,
        Plot,
        SimpleList,
        p: Paragraph,
        Datatable: SimpleTable,
    };

    export default {
        name: 'CustomReportPageViewer',

        components: {
            Title,
            Metric,
        },

        props: {
            reportId: {
                type: String,
                required: true,
            },
            pageId: {
                type: String,
                required: true,
            },
        },

        data: () => ({
            request: undefined,
            page: undefined,
        }),

        watch: {
            pageId: {
                immediate: true,
                handler(newValue) {
                    this.request = () => CustomReportAPI.getReportPage(this.reportId, newValue);
                },
            },
        },

        methods: {
            onRequestSuccess(data) {
                this.page = data;
            },
            getComponent(com) {
                return components[com];
            },
        },
    };
</script>
