<template>
    <div>
        <b>{{ user }}</b>
        <p>{{ content }}</p>
    </div>
</template>

<script>
    export default {
        name: 'ReportCommentDisplay',

        props: {
            user: {
                type: String,
                required: true,
            },
            content: {
                type: String,
                required: true,
            },
            commentId: {
                type: String,
                required: true,
            },
        },

        methods: {

        },
    };
</script>
