import Vue from 'vue';
import VueGtm from 'vue-gtm';
import VueECharts from 'vue-echarts';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
// import * as echarts from 'echarts/lib/echarts';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import helpersPlugin from './plugins/helpers';
// impaort gtmPlugin from './plugins/gtm';
import './plugins/filters';

// -- ECHARTS --
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/bar';
// import 'echarts/lib/chart/pie';
// import 'echarts/lib/chart/scatter';
// import 'echarts/lib/chart/radar';
// import 'echarts/lib/chart/map';
// import 'echarts/lib/chart/treemap';
// import 'echarts/lib/chart/graph';
// import 'echarts/lib/chart/gauge';
// import 'echarts/lib/chart/funnel';
// import 'echarts/lib/chart/parallel';
// import 'echarts/lib/chart/sankey';
// import 'echarts/lib/chart/boxplot';
import 'echarts/lib/chart/candlestick';
// import 'echarts/lib/chart/effectScatter';
// import 'echarts/lib/chart/lines';
// import 'echarts/lib/chart/heatmap';
// import 'echarts/lib/component/graphic';
// import 'echarts/lib/component/grid';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/polar';
// import 'echarts/lib/component/geo';
// import 'echarts/lib/component/parallel';
// import 'echarts/lib/component/singleAxis';
// import 'echarts/lib/component/brush';
import 'echarts/lib/component/title';
import 'echarts/lib/component/dataZoom';
// import 'echarts/lib/component/visualMap';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/markLine';
// import 'echarts/lib/component/markArea';
// import 'echarts/lib/component/timeline';
import 'echarts/lib/component/toolbox';
// import 'zrender/lib/vml/vml';
import 'echarts';

Vue.config.productionTip = false;
Vue.use(helpersPlugin);
// Vue.use(gtmPlugin(router));
Vue.component('v-chart', VueECharts);

// Sentry
Sentry.init({
    Vue,
    dsn: 'https://64ded06cbc1842eda865bfe0fd1cf084@o578441.ingest.sentry.io/5734675',
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: [
                'api.goodish.local',
                'api.app-dev.goodish.local',
                'app-dev.goodish.local',
            ],
        }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 0.1,
    // Show errors in browser console
    logErrors: true,
});

Vue.use(VueGtm, {
    id: 'GTM-5QND4F7',
    defer: false,
    enabled: true,
    debug: true,
    loadScript: true,
    vueRouter: router,
    ignoredViews: ['homepage'],
    trackOnNextTick: false,
});

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
