import webapi from '@/core/webapi';
import CONSTANTS from '@/constants';
import { UserProfile, UserProfileSerializer } from './serializers';

const HOME_URI = `${CONSTANTS.API_BASE}/home`;

export default class MainAPI {
    static async getMetadata() : Promise<any> {
        console.log('METADATA REQUEST FORM MAIN API');
        const uri = `${HOME_URI}/metadata`;
        return webapi.get({ uri });
    }

    static async getUserCards() : Promise<any> {
        const uri = `${HOME_URI}/cards`;
        return webapi.get({ uri });
    }

    static async saveUserProfile(profile: UserProfile) : Promise<any> {
        const uri = `${CONSTANTS.API_BASE}/user/current/profile`;
        const serializer = new UserProfileSerializer(profile);
        const body = serializer.serialize();
        return webapi.put({ uri, body });
    }
}
