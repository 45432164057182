<template>
    <v-menu
        :nudge-width="200"
        offset-x
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="indigo"
                dark
                v-bind="attrs"
                v-on="on"
                icon
            >
                <i-icon color="white" icon="$user" />
            </v-btn>
        </template>

        <v-card>
            <v-list dense>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>{{ username }}</v-list-item-title>
                        <v-list-item-subtitle>{{ accountType }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-divider/>

            <v-list dense>
                <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    :to="item.to"
                    :disabled="item.disabled"
                >
                    <v-list-item-icon>
                        <v-icon small v-text="item.icon"/>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.label"/>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-switch v-model="pii" label="PII" />
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        name: 'UserMenu',
        props: {
            username: {
                type: String,
                default: 'joe.doe@example.com',
            },
            accountType: {
                type: String,
                default: 'Trial Account',
            },
        },
        data: () => ({
            items: [
                {
                    label: 'Edit profile',
                    icon: '$user',
                    to: '/auth/edit-profile/',
                    disabled: true,
                },
                {
                    label: 'Change password',
                    icon: 'fa-unlock-alt',
                    to: '/auth/change-password/',
                    disabled: true,
                },
                { label: 'Logout', icon: 'fa-sign-out-alt', to: '/auth/logout' },
            ],
            pii: false,
        }),

        watch: {
            pii(newValue) {
                this.$store.commit('core/pii', newValue);
            },
        },
    };
</script>
