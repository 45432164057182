<template>
    <v-row>
        <v-col>
            Custom Report Page View
        </v-col>
    </v-row>
</template>

<script>

    export default {
        name: 'CustomReportPageView',

        components: {
        },

        data: () => ({
            selectedReportId: undefined,
            showReport: false,
        }),

        methods: {
            onReportClick(reportId) {
                this.selectedReportId = reportId;
                this.showReport = true;
            },
        },
    };
</script>
